//Constant
export const GUEST_USER_LOGIN = "GUEST_USER_LOGIN";
export const CART_REDIRECT = "CART_REDIRECT";
export const CART_LOGIN = "CART_LOGIN";
export const GUEST_USER_LOGOUT = "GUEST_USER_LOGOUT";
export const ORDER_REGULAR = "regular";
export const ORDER_PRE = "pre-order";
export const ORDER_ALL = "all-order";

// initial state
const initialState = {
  email: "",
};

const initialStateforCartRedirect = CART_REDIRECT;
const initialStateforOrderType = ORDER_REGULAR;

export const GuestUserReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GUEST_USER_LOGIN:
      return { email: payload };
    case GUEST_USER_LOGOUT:
      return { email: "" };
    default: {
      return state;
    }
  }
};

export const PurchaseCartReducer = (
  state = initialStateforCartRedirect,
  action
) => {
  const { type } = action;
  switch (type) {
    case CART_LOGIN:
      return CART_LOGIN;
    case CART_REDIRECT:
      return CART_REDIRECT;
    default: {
      return state;
    }
  }
};

export const SetOrderTypeForGuest = (
  state = initialStateforOrderType,
  action
) => {
  switch (action.type) {
    case ORDER_REGULAR:
      return ORDER_REGULAR;
    case ORDER_PRE:
      return ORDER_PRE;
    case ORDER_ALL:
      return ORDER_ALL;
    default:
      return state;
  }
};

// functions
export const loginGuestUser = (payload) => ({
  type: GUEST_USER_LOGIN,
  payload,
});

export const logoutGuestUser = () => ({
  type: GUEST_USER_LOGOUT,
});

export const loginBeforeCart = ({ type }) => ({ type });

export const setOrderType = ({ type }) => ({ type });
