import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { GLOBAL_SEARCH } from "../../../graphql/modules/home";
import { useHistory } from "react-router-dom";
import Highlighter from "react-highlight-words";

const DrawerSearch = ({ searchBar, openSearchBar }) => {
  const wrapRefUser = useRef();
  const [searchTxt, setSearchTxt] = useState("");
  const history = useHistory();

  const { data } = useQuery(GLOBAL_SEARCH, {
    variables: {
      searchTxt,
    },
  });

  // console.log(data);
  let resultOfProduct = [];
  const suggestProduct =
    data?.FetchGlobalSearchProduct?.products?.map(
      ({ name, _id, category, subcategory }) => {
        let productFromCategory = [];
        let productFromSubcategory = [];
        productFromCategory = category?.map((ctPro) => ({
          name,
          _id,
          type: "category",
          ref: ctPro?._id,
          parentName: ctPro?.name,
        }));
        productFromSubcategory = subcategory?.map((ctPro) => ({
          name,
          _id,
          type: "subcategory",
          ref: ctPro?._id,
          parentName: ctPro?.name,
        }));
        resultOfProduct.push(...productFromSubcategory, ...productFromCategory);
        return { name, _id };
      }
    ) || [];
  const suggestSubcategory =
    data?.FetchGlobalSearchProduct?.subcategories || [];
  const suggestCategory = data?.FetchGlobalSearchProduct?.categories || [];

  useEffect(() => {
    setSearchTxt("");
  }, [searchBar]);


  const onRedirect = (_id, isProduct = false, isCategory = false, ref) => {
    if (isCategory) {
      history.push(`/category/${_id}?type=categoryId`);
    } else if (isProduct) history.push(`/products/${_id}?ref=${ref}`);
    else history.push(`/category/${_id}?type=subcategoryId`);
    openSearchBar();
  };

  // useEffect(() => {
  //   if (searchBar) {
  //     const mt = document.querySelector("#lempor_nayeem")?.offsetHeight || 0;
  //     wrapRefUser.current.style.setProperty("top", mt + `px`, "important");
  //   } else {
  //     wrapRefUser.current.style.setProperty("top", -60 + `px`, "important");
  //   }
  // }, [searchBar]);

  return (
    <section
      className={`nav-search-area overlay ${searchBar ? "top_zero" : ""}`}
      ref={wrapRefUser}
    >
      <div className="search-container">
        <div className="inner-search">
          <form action="#">
            <div className="input-group search-group">
              <span className="input-group-text icon-part">
                <i style={{ color: "#ececec" }} className="fas fa-search"></i>
              </span>
              <input
                type="text"
                className="form-control search-control"
                placeholder="Search chillfitrave.com"
                value={searchTxt}
                style={{ color: "#ececec !important" }}
                onChange={(e) => setSearchTxt(e.target.value)}
              />
              <span className="input-group-text icon-part dismiss-search">
                <img
                  onClick={openSearchBar}
                  src="/images/times-light.svg"
                  alt="cancel"
                />
              </span>
            </div>
          </form>
        </div>
      </div>
      {searchBar && searchTxt && (
        <div
          className="search_bar_area"
          style={{ height: "80vh", overflow: "auto" }}
        >
          <div className="inner_searchbar_main">
            <p>Suggestions</p>
            <hr />
            {suggestSubcategory.map(({ name, _id }) => (
              <div
                onClick={() => onRedirect(_id, false, false)}
                className="mb-2"
              >
                <Highlighter
                  key={_id}
                  highlightClassName="highlight-class"
                  searchWords={searchTxt.split(" ")}
                  // autoEscape={true}
                  textToHighlight={name}
                />
              </div>
            ))}

            {suggestCategory.map(({ name, _id }) => (
              <div
                onClick={() => onRedirect(_id, false, true)}
                className="mb-2"
              >
                <Highlighter
                  key={_id}
                  highlightClassName="highlight-class"
                  searchWords={searchTxt.split(" ")}
                  // autoEscape={true}
                  textToHighlight={name}
                />
              </div>
            ))}

            {resultOfProduct.map(({ name, _id, ref, parentName }) => (
              <div
                onClick={() => onRedirect(_id, true, false, ref)}
                key={ref+parentName+_id}
                className="mb-2"
              >
                <Highlighter
                  key={_id}
                  highlightClassName="highlight-class"
                  searchWords={searchTxt.split(" ")}
                  textToHighlight={name + " > " + parentName}
                />
              </div>
            ))}

            {suggestProduct.length === 0 &&
              suggestSubcategory.length === 0 &&
              suggestCategory.length === 0 && (
                <p>
                  No results for <strong>{searchTxt}</strong>
                </p>
              )}
            {/* <p>Contact us</p>
            <p>Refund & exchange</p>
            <p>Join us</p>
            <p>FAQ</p> */}
          </div>
        </div>
      )}
    </section>
  );
};

export default DrawerSearch;
