import React, { useState } from "react";
// import "intl-tel-input/build/css/intlTelInput.css";
import { Button } from "../Shared";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import moment from "moment";
import { LOGIN, onRediret } from "../../store/modules";
import { useDispatch } from "react-redux";
import { useMutation } from "@apollo/react-hooks";
import { USER_SIGNUP } from "../../graphql/modules/auth";
import { SHOPPING_ID, successNotify, warnNotify } from "../../util";
import { Link } from "react-router-dom";
const RegisterShopping = () => {
  // registration input state initialize
  const [userData, setUserData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    password: "",
    birthDate: "",
  });
  const [err, setErr] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    password: "",
    birthDate: "",
    cpassword: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);
  const [checked, setChecked] = useState(false);

  // initilize dispatch hook
  const dispatch = useDispatch();
  // const handleRedirect = () => {
  //   setUserData({
  //     firstname: "",
  //     lastname: "",
  //     email: "",
  //     phone: "",
  //     password: "",
  //     birthDate: "",
  //   });
  //   dispatch(onRediret({ type: LOGIN }));
  // };

  // confirm password state
  const [cpassword, setCpassword] = useState("");

  // days based on month
  const dayArray = () => {
    let days = [];
    for (var i = 1; i <= months[0].day; i++) {
      days.push(i);
    }
    return days;
  };

  // 56 years initilize
  const yearsArray = () => {
    const currentYear = Number(new Date().getFullYear()) - 15;
    let years = [];
    for (var i = currentYear - 56; i <= currentYear; i++) {
      years.push(i);
    }
    return years;
  };

  // handle confirm pass

  const handleConfirmPass = (e) => {
    setCpassword(e.target.value);
    if (e.target.value !== userData.password) {
      setErr({ ...err, cpassword: "Password doesn't match!" });
    } else {
      setErr({ ...err, cpassword: "" });
    }
  };

  // onChange input
  const handleOnchange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
    var mediumPasswordChecking = new RegExp(
      "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})"
    );
    var nameChecking = new RegExp("^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$");
    if (name === "firstname") {
      if (value) {
        if (!nameChecking.test(value)) {
          setErr({
            ...err,
            firstname: "No numbers or special characters for the name",
          });
        } else {
          setErr({ ...err, firstname: "" });
        }
      } else {
        setErr({ ...err, firstname: "" });
      }
    }
    if (name === "lastname") {
      if (value) {
        if (!nameChecking.test(value)) {
          setErr({
            ...err,
            lastname: "No numbers or special characters for the name",
          });
        } else {
          setErr({ ...err, lastname: "" });
        }
      } else {
        setErr({ ...err, lastname: "" });
      }
    }
    if (name === "password") {
      if (value) {
        if (!mediumPasswordChecking.test(value) || value.length < 6) {
          setErr({
            ...err,
            password:
              "Please enter 6 characters minimum with the combination of numbers and letters.",
          });
        } else {
          setErr({ ...err, password: "" });
        }
      } else {
        setErr({ ...err, password: "" });
      }
    }
    if (name === "email") {
      const emailValidator =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (value) {
        if (!emailValidator.test(String(value).toLowerCase())) {
          setErr({
            ...err,
            email: "Please enter a valid email, e.g. name@domain.com",
          });
        } else {
          setErr({ ...err, email: "" });
        }
      } else {
        setErr({ ...err, email: "" });
      }
    }
  };

  //birthdate convert to milliseconds
  const [birth, setBirth] = useState({
    month: 1,
    day: 1,
    year: 1950,
  });

  const handleBirthday = (e) => {
    const { name, value } = e.target;
    setBirth({ ...birth, [name]: value });
    const millisecondsDay = moment(
      `${birth.month} ${birth.day} ${value}`,
      "MM DD YYYY"
    ).format("x");
    setUserData({ ...userData, birthDate: millisecondsDay });
  };
  const handleBirthmonth = (e) => {
    const { name, value } = e.target;
    setBirth({ ...birth, [name]: value });
    const millisecondsDay = moment(
      `${birth.month} ${birth.day} ${value}`,
      "MM DD YYYY"
    ).format("x");
    setUserData({ ...userData, birthDate: millisecondsDay });
  };
  const handleBirthyear = (e) => {
    const { name, value } = e.target;
    setBirth({ ...birth, [name]: value });
    const millisecondsDay = moment(
      `${birth.month} ${birth.day} ${value}`,
      "MM DD YYYY"
    ).format("x");
    setUserData({ ...userData, birthDate: millisecondsDay });
  };

  // submit create account
  const [onCreateAccount, { loading }] = useMutation(USER_SIGNUP);
  const handleSubmit = async () => {
    try {
      const {
        data: { Register },
      } = await onCreateAccount({
        variables: {
          userInput: { ...userData, email: userData?.email?.toLowerCase() },
        },
      });
      if (Register.success) {
        successNotify(Register.message);
        handleBack();
      } else {
        warnNotify(Register.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //go login
  const handleBack = () => {
    dispatch(onRediret({ type: LOGIN }));
  };
  // disable based on input value
  const disabled =
    !userData.email ||
    !userData.birthDate ||
    !userData.firstname ||
    !userData.lastname ||
    !userData.password ||
    userData.password !== cpassword ||
    err.firstname ||
    err.lastname ||
    err.password ||
    !checked;

  const btnStyle = {
    bordeRadius: "0",
    backgroundColor: "transparent",
    border: "0",
    textDecoration: "underline",
    textDecorationColor: "#3d3d3d",
    color: "#3d3d3d",
    // position: "absolute",
    padding: "0",
  };

  return (
    <div>
      <div>
        <button style={btnStyle} onClick={handleBack} id={SHOPPING_ID} >
          Return to login
        </button>
      </div>
      <div className="login-title">
        <h5>Sign Up</h5>
      </div>
      <form action="">
        <div className="mb-3">
          <label htmlFor="firstname" className="form-label" style={{color:"#3d3d3d"}}>
            First Name*
          </label>
          <input
            type="text"
            className="form-control cmn-control"
            id="firstname"
            name="firstname"
            onChange={handleOnchange}
            value={userData.firstname}
            style={{color:"#3d3d3d"}}
          />
          {err.firstname && (
            <div className="invalid-feedback d-flex align-items-center" style={{color:"#3d3d3d"}}>
              <span className="mr-1">
                <img src="/images/warning.svg" alt="" />
              </span>
              {err.firstname}
            </div>
          )}
        </div>

        <div className="mb-3">
          <label htmlFor="lastname" className="form-label" style={{color:"#3d3d3d"}}>
            Last Name*
          </label>
          <input
            type="text"
            className="form-control cmn-control"
            id="lastname"
            name="lastname"
            onChange={handleOnchange}
            value={userData.lastname}
            style={{color:"#3d3d3d"}}
          />
          {err.lastname && (
            <div className="invalid-feedback d-flex align-items-center" style={{color:"#3d3d3d"}}>
              <span>
                <img src="/images/warning.svg" alt="" />
              </span>
              {err.lastname}
            </div>
          )}
        </div>

        <div className="mb-3">
          <label htmlFor="email" className="form-label" style={{color:"#3d3d3d"}}>
            Email*
          </label>
          <input
            type="email"
            className="form-control cmn-control"
            id="email"
            name="email"
            onChange={handleOnchange}
            value={userData.email?.toLowerCase()}
            style={{color:"#3d3d3d"}}
          />
          {err.email && (
            <div className="invalid-feedback d-flex align-items-center" style={{color:"#3d3d3d"}}>
              <span className="mr-1">
                <img src="/images/warning.svg" alt="" />
              </span>
              {err.email}
            </div>
          )}
        </div>

        <div className="mb-3">
          <label htmlFor="phone" className="form-label" style={{color:"#3d3d3d"}}>
            Phone Number*
          </label>
          <br />
          <IntlTelInput
            containerClassName="intl-tel-input w-100"
            inputClassName="form-control"
            onPhoneNumberChange={(status, value, countryData, number, id) => {
              setUserData({ ...userData, phone: number });
              const phoneValidator =
                /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
              if (!phoneValidator.test(value)) {
                setErr({ ...err, phone: "Phone number is not valid!" });
              } else {
                setErr({ ...err, phone: "" });
              }
            }}
          />

          <div id="emailHelp" className="form-text" style={{color:"#3d3d3d"}}>
            Change country code by taping the flag.
          </div>
          {err.phone && (
            <div className="invalid-feedback d-flex align-items-center">
              <span>
                <img src="/images/warning.svg" alt="" />
              </span>
              {err.phone}
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-6">
            <label htmlFor="password" className="form-label" style={{color:"#3d3d3d"}}>
              Password*
            </label>
          </div>
          <div className="col-6">
            <label htmlFor="password_show" className="form-label password_show" style={{color:"#3d3d3d"}}>
              <p
                onClick={() => setShowPassword(!showPassword)}
                className="show_pass"
                // id={SHOPPING_ID}
              >
                {showPassword ? "Hide" : "Show"}
              </p>
            </label>
          </div>
        </div>
        <div className="mb-3">
          <input
            type={showPassword ? "text" : "password"}
            className="form-control cmn-control"
            id="password"
            onChange={handleOnchange}
            name="password"
            value={userData.password}
          />
          {err.password && (
            <div className="invalid-feedback d-flex align-items-top" style={{color:"#3d3d3d"}}>
              <span>
                <img src="/images/warning.svg" alt="" />
              </span>
              {err.password}
            </div>
          )}
        </div>

        <div className="row">
          <div className="col-6">
            <label htmlFor="password" className="form-label" style={{color:"#3d3d3d"}}>
              Confirm Password*
            </label>
          </div>
          <div className="col-6">
            <label htmlFor="password_show" className="form-label password_show" style={{color:"#3d3d3d"}}>
              <p
                onClick={() => setShowCPassword(!showCPassword)}
                className="show_pass"
              >
                {showCPassword ? "Hide" : "Show"}
              </p>
            </label>
          </div>
        </div>
        <div className="mb-3">
          <input
            type={showCPassword ? "text" : "password"}
            className="form-control cmn-control"
            id="cpassword"
            name="cpassword"
            onChange={handleConfirmPass}
            style={{color:"#3d3d3d"}}
            value={cpassword}
          />
          {err.cpassword && (
            <div className="invalid-feedback d-flex align-items-center" style={{color:"#3d3d3d"}}>
              <span>
                <img src="/images/warning.svg" alt="" />
              </span>
              {err.cpassword}
            </div>
          )}
        </div>

        <div className="row">
          <div className="col-12">
            <div className="mb-3">
              <label htmlFor="confirm_password" className="form-label" style={{color:"#3d3d3d"}}>
                Birthday*
              </label>
              <br />

              <label htmlFor="confirm_password" className="form-label" style={{color:"#3d3d3d"}}>
                Month
              </label>
              <select
                className="form-select cmn-control"
                name="month"
                onChange={handleBirthmonth}
                required
                style={{color:"#3d3d3d"}}
              >
                <option style={{color:"#3d3d3d"}} value="">Select Month</option>
                {months.map((item) => (
                  <option style={{color:"#3d3d3d"}} key={item.value} value={item.value}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="col">
            <div className="mb-3">
              <label htmlFor="confirm_password" className="form-label" style={{color:"#3d3d3d"}}>
                Day
              </label>
              <select
                className="form-select cmn-control"
                name="day"
                onChange={handleBirthday}
                style={{color:"#3d3d3d"}}
              >
                {dayArray().map((day) => (
                  <option style={{color:"#3d3d3d"}} key={day} value={day}>
                    {day}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="col">
            <div className="mb-3">
              <label htmlFor="confirm_password" className="form-label" style={{color:"#3d3d3d"}}>
                Year
              </label>
              <select
                className="form-select cmn-control"
                name="year"
                onChange={handleBirthyear}
                style={{color:"#3d3d3d"}}
              >
                {yearsArray().map((year) => (
                  <option style={{color:"#3d3d3d"}} key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="col-12">
            <div className="form-check">
              <input
                className="form-check-input agree_check"
                type="checkbox"
                id={SHOPPING_ID}
                onClick={() => setChecked(!checked)}
                style={{color:"#3d3d3d"}}
              />
              <label className="form-check-label" htmlFor={SHOPPING_ID} style={{color:"#3d3d3d"}}>
                I acknowledge that I have read the{" "}
                <Link to="/faq">Privacy policy</Link> and agree to the{" "}
                <Link to="/faq">&nbsp;term of use</Link>.
              </label>
            </div>
          </div>
        </div>
        <Button
          btnText="Create Now"
          disabled={disabled}
          loading={loading}
          onClick={handleSubmit}
        />
      </form>
    </div>
  );
};

const months = [
  { name: "January", value: "01", day: 31 },
  { name: "February", value: "02", day: 28 },
  { name: "March", value: "03", day: 31 },
  { name: "April", value: "04", day: 30 },
  { name: "May", value: "05", day: 31 },
  { name: "June", value: "06", day: 30 },
  { name: "July", value: "07", day: 31 },
  { name: "August", value: "08", day: 31 },
  { name: "September", value: "09", day: 30 },
  { name: "October", value: "10", day: 31 },
  { name: "November", value: "11", day: 30 },
  { name: "December", value: "12", day: 31 },
];

export default RegisterShopping;
