//Constant
export const SHIPPING = "SHIPPING";
export const BILLING = "BILLING";

// initial state
const initialState = SHIPPING;

export const checkOut = (state = initialState, action) => {
  switch (action.type) {
    case SHIPPING:
      return SHIPPING;
    case BILLING:
      return BILLING;
    default: {
      return SHIPPING;
    }
  }
};

export const redirectToCheckOut = ({ type }) =>({type})
