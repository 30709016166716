import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  ForgotPassword,
  Login,
  PrivateMenu,
  Register,
} from "../../../components";
import { CREATE_ACCOUNT, FORGOT_PASSWORD, LOGIN } from "../../../store/modules";

const DrawerRight = ({ userDrawerClassName, wrapRefUser }) => {
  const auth = useSelector((state) => state.auth);
  const redirect = useSelector((state) => state.redirect);

  const handleRenderComponent = () => {
    switch (redirect) {
      case LOGIN:
        return <Login />;
      case FORGOT_PASSWORD:
        return <ForgotPassword />;
      case CREATE_ACCOUNT:
        return <Register />;
      default:
        return <Login />;
    }
  };
  useEffect(() => {
    const mt =
      (document.querySelector("#lempor_rasel")?.offsetHeight || 0) +
      (document.querySelector(".navbar-area").offsetHeight || 0);
    const hight =
      (document.querySelector("body")?.offsetHeight || 0) -
      (document.querySelector("#lempor_rasel")?.offsetHeight || 0);

    wrapRefUser.current.style.setProperty("top", mt + `px`, "important");
    wrapRefUser.current.style.setProperty("height", hight + `px`, "important");
  }, [userDrawerClassName]);

  // useLayoutEffect(() => {
  //   const mt =
  //     (document.querySelector("#lempor_nayeem")?.offsetHeight || 0) + 162;
  //   console.log(mt, document.querySelector("#lempor_nayeem")?.offsetHeight);
  //   wrapRefUser.current.style.setProperty("top", mt + `px`, "important");
  // }, []);

  const privateLayout = (
    <div
      className="shopping-cart-inner-drawer"
      style={{ marginBottom: "100px" }}
    >
      <PrivateMenu />
    </div>
  );
  const style = {
    marginTop: `${
      (document.querySelector("#lempor_rasel")?.offsetHeight || 0) + 62
    }px important`,
    position: "fixed",
  };
  return (
    <div
      className={`user-drawer ${userDrawerClassName ? "overlay" : ""}`}
      id="user-drawer"
    >
      <div
        ref={wrapRefUser}
        className={`user-drawer-container ${
          userDrawerClassName ? "right_zero" : ""
        }`}
        style={style}
      >
        {auth?.isAuthenticated ? privateLayout : handleRenderComponent()}
      </div>
    </div>
  );
};

export default DrawerRight;
