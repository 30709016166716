import {
  Home,
  SignUpPage,
  SignIn,
  ForgotPassword,
  EmailVerificationReq,
  // EmailVerify,
  ResetPassword,
  NewEmailVerify,
  ProductList,
  OrderDetails,
  ProductDetails,
  WelcomeBoard,
  Checkout,
  OrderConfirmation,
  Shipping,
  Contact,
  Customization,
  Payment,
  Return_and_exchange,
  About,
  Join,
  FAQ,
  // Extrapage,
  Unsubscribe,
  OutOfStock,
  Test,
  Legal,
  ProccesingOrder
} from "../pages";

// Login or Sign in Routes
export const Auth = [
  { path: "/signup", exact: true, component: SignUpPage },
  { path: "/login", exact: true, component: SignIn },
  { path: "/forgot-password", exact: true, component: ForgotPassword },
  { path: "/reset-password/:id", component: ResetPassword },
  { path: "/verification-req", exact: true, component: EmailVerificationReq },
  { path: "/change-email", exact: true, component: NewEmailVerify },
];

// Public Routes
export const Public = [
  { path: "/", exact: true, component: Home },
  { path: "/test", exact: true, component: Test },
  { path: "/category/:id", exact: true, component: ProductList },
  { path: "/products/:id", component: ProductDetails },
  { path: "/order-confirmation/:id", exact: true, component: OrderConfirmation },
  { path: "/order-proccessing", exact: true, component: ProccesingOrder },
  { path: "/contact", exact: true, component: Contact },
  { path: "/customization", exact: true, component: Customization },
  { path: "/payment", exact: true, component: Payment },
  { path: "/return-and-exchange", exact: true, component: Return_and_exchange },
  { path: "/about", exact: true, component: About },
  { path: "/stock-out", exact: true, component: OutOfStock },
  { path: "/join", exact: true, component: Join },
  { path: "/shipping", exact: true, component: Shipping },
  { path: "/faq", exact: true, component: FAQ },
  { path: "/legal", exact: true, component: Legal },
  { path: "/unsubscribe", exact: true, component: Unsubscribe },
];
// Private Routes
export const Private = [
  { path: "/welcome-board", exact: true, component: WelcomeBoard },
  { path: "/order-details/:id", exact: true, component: OrderDetails },
];

export const Common = [
  { path: "/checkout", exact: true, component: Checkout }
]
