import React, { Suspense } from "react";
import { createBrowserHistory } from "history";
import { Switch, Route, Router } from "react-router-dom";

// Index Routes
import { Auth, Common, Private, Public } from "./Router";
import { AuthRoute, PrivateRoute, PublicRoute, CommonRoute } from "./AllRoute";
import { NotFound } from "../pages";
import Layout from "../containers/Layout";
import SuspenseFallback from "../pages/SuspenseFallback";
// Routes

// Components
export const history = createBrowserHistory();

const RouterConfig = () => {

  return (
    <Router history={history}>
      <Suspense fallback={<SuspenseFallback />}>
        <Layout>
          <Switch>
            {Private.map((R, k) => {
              return <PrivateRoute key={k} {...R} />;
            })}
            {Auth.map((R, k) => {
              return <AuthRoute key={k} {...R} />;
            })}

            {Public.map((R, k) => {
              return <PublicRoute key={k} {...R} />;
            })}
            {Common.map((R, k) => {
              return <CommonRoute key={k} {...R} />;
            })}
            <Route component={NotFound} />
          </Switch>
        </Layout>
      </Suspense>
    </Router>
  );
};

export const AppRouter = RouterConfig;
