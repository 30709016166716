import {
  useLazyQuery,
  useMutation,
  useQuery,
  useSubscription,
} from "@apollo/react-hooks";
import { Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  CartItems,
  ForgotPasswordShopping,
  LoginShopping,
  PrivateMenu,
  RegisterShopping,
} from "../../../components";
import CheckoutLogin from "../../../components/ShoppingCart/CheckoutLogin";
import {
  ADD_TO_SHOPPING_CART,
  FETCH_DISCOUNT_ON_PURCHASE,
  FETCH_PRODUCT_AVAILABLE,
  FETCH_SHOPPING_CART,
  PRODUCT_UPDATE_SUBS,
} from "../../../graphql/modules";
import {
  CART_REDIRECT,
  CREATE_ACCOUNT,
  FORGOT_PASSWORD,
  LOGIN,
  SHOPPING,
  updateCartProductStore,
  updatePreOrder,
  updateRegularOrder,
} from "../../../store/modules";
import { UpdateCartProduct } from "../../../util";
// import {
//   addItemToCartFromApi,
//   addItemToCartPreOrderFromApi,
// } from "../../../store";

const DrawerShopping = ({
  shoppingClassName,
  openShoppingCart,
  wrapRefShopping,
  ...props
}) => {
  const user = useSelector((state) => state.auth);
  const redirectForMobile = useSelector((state) => state.redirectForMobile);
  const cartLogin = useSelector((state) => state.cartLogin);
  const { items: cartItem, preItems } = useSelector((state) => state.cart);
  const cardProdIds = [...preItems, ...cartItem].map((item) => item?._id);

  const dispatch = useDispatch();

  // const [loadShoppingCart, { data: shoppingCart, loading: cartLoading }] =
  //   useLazyQuery(FETCH_SHOPPING_CART, {
  //     variables: {
  //       id: user?.user?._id,
  //     },
  //   });
  // const fetchItemFromApi = shoppingCart?.FetchShoppingCart?.result || [];

  const {
    data: cartProduct,
    refetch,
    subscribeToMore,
    loading,
  } = useQuery(FETCH_PRODUCT_AVAILABLE, {
    variables: {
      productIds: cardProdIds,
    },
    fetchPolicy:"network-only"
  });
  const userCartProducts = cartProduct?.FetchBulkProduct?.result || [];
  // console.log(cardProdIds)

  useEffect(() => {
    const { cartItem: updateCartItem, preItem: updatePreItem } =
      UpdateCartProduct([...cartItem, ...preItems], userCartProducts);
    if (updateCartItem.length) {
      dispatch(
        updateCartProductStore({
          cartItem: updateCartItem,
          preItem: updatePreItem || [],
        })
      );
    }
  }, [userCartProducts]);

  const fetchMore = () => {
    subscribeToMore({
      document: PRODUCT_UPDATE_SUBS,
      updateQuery: (prev, { subscriptionData }) => {
        refetch({});
        // UpdateCartProduct(cartItem, userCartProducts )
      },
    });
  };
  useEffect(() => {
    fetchMore();
  }, []);

  const handleRenderComponent = () => {
    switch (redirectForMobile) {
      case LOGIN:
        return <LoginShopping />;
      case SHOPPING:
        return (
          <CartItems
            openShoppingCart={openShoppingCart}
            // fetchItemFromApi={fetchItemFromApi}
            // cartLoading={cartLoading}
            shoppingClassName={shoppingClassName}
          />
        );
      case FORGOT_PASSWORD:
        return <ForgotPasswordShopping />;
      case CREATE_ACCOUNT:
        return <RegisterShopping />;
      default:
        return <CartItems openShoppingCart={openShoppingCart} />;
    }
  };

  useEffect(() => {
    const mt =
      (document.querySelector("#lempor")?.offsetHeight || 0) +
      document.querySelector(".navbar-area").offsetHeight +
      (document.querySelector(".announcement")?.offsetHeight || 0);
    wrapRefShopping.current.style.setProperty("top", mt + `px`, "important");
  }, [shoppingClassName]);

  return (
    <div
      className={`shopping-cart-drawer ${shoppingClassName ? "overlay" : ""}`}
      id="shopping-cart-drawer"
    >
      <div
        ref={wrapRefShopping}
        className={`shopping-cart-drawer-container inner-drawer ${
          shoppingClassName ? "right_zero" : ""
        }`}
        // style={style}
      >
        <div
          // className="shopping-cart-inner-drawer"
          className={`shopping-cart-inner-drawer  ${
            shoppingClassName ? "" : "d_n"
          }`}
          style={{ marginBottom: "100px", cursor: "auto" }}
        >
          {/* <CartItems/> */}
          {/* <Login/> */}
          {cartLogin === CART_REDIRECT ? (
            handleRenderComponent()
          ) : (
            <CheckoutLogin {...props} />
          )}
          {/* {handleRenderCheckoutComponent()} */}

          {/* <!-- Shopping cart calculation --> */}
          <div className="auth-shopping">
            {!user.isAuthenticated && redirectForMobile === SHOPPING && (
              <PrivateMenu toLogin={true} />
            )}
          </div>
          {/* <!-- Shopping Cart List --> */}
          {user.isAuthenticated && <PrivateMenu />}
          {/* <!-- Shopping Cart ListEnd --> */}
        </div>
      </div>
    </div>
  );
};

export default DrawerShopping;

// const findSize = (sizeName, sizeList = []) => {
//   var size = sizeList?.find((item) => sizeName === item?.size?.name);
//   return size;
// };

// import { useLazyQuery, useMutation } from "@apollo/react-hooks";
// import React, { useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
// import { useSelector } from "react-redux";
// import {
//   CartItems,
//   ForgotPasswordShopping,
//   LoginShopping,
//   PrivateMenu,
//   RegisterShopping,
// } from "../../../components";
// import CheckoutLogin from "../../../components/ShoppingCart/CheckoutLogin";
// import {
//   ADD_TO_SHOPPING_CART,
//   FETCH_PRODUCT_AVAILABLE,
//   FETCH_SHOPPING_CART,
// } from "../../../graphql/modules";
// import {
//   CART_REDIRECT,
//   CREATE_ACCOUNT,
//   FORGOT_PASSWORD,
//   LOGIN,
//   SHOPPING,
// } from "../../../store/modules";
// import {
//   addItemToCartFromApi,
//   addItemToCartPreOrderFromApi,
// } from "../../../store";

// const DrawerShopping = ({
//   shoppingClassName,
//   openShoppingCart,
//   wrapRefShopping,
//   ...props
// }) => {
//   const user = useSelector((state) => state.auth);
//   const redirectForMobile = useSelector((state) => state.redirectForMobile);
//   const cartLogin = useSelector((state) => state.cartLogin);
//   const { items: cartItem, preItems } = useSelector((state) => state.cart);

//   const dispatch = useDispatch();

//   const [loadShoppingCart, { data: shoppingCartData, loading: cartLoading }] =
//     useLazyQuery(FETCH_SHOPPING_CART, {
//       variables: {
//         id: user?.user?._id,
//       },
//       fetchPolicy: "no-cache",
//     });
//   const shoppingCart = shoppingCartData?.FetchShoppingCart?.result || [];

//   // get products for check availibilty
//   const [
//     loadProductForQuantity,
//     { data: allCartProductDetailsFromApi, loading: productLoading },
//   ] = useLazyQuery(FETCH_PRODUCT_AVAILABLE);
//   const allProduct =
//     allCartProductDetailsFromApi?.FetchBulkProduct?.result || [];

//   // will call on product load
//   useEffect(() => {
//     if (allProduct.length) {
//       const newItems = [],
//         newPreItems = [];
//       const distrinctProduct = getDistrinctProducts([
//         ...cartItem,
//         ...preItems,
//         ...shoppingCart,
//       ]);
//       distrinctProduct.map((item) => {
//         const { topSizing, bottomSizing, product, quantity } = item;
//         allProduct.map((innerItem) => {
//           console.log(findSize(bottomSizing, innerItem.bottomSizing))
//           const isRegulerOrder =
//             innerItem._id === product._id &&
//             ((product.isAccessories && quantity >= innerItem.quantity) ||
//               (!product.isAccessories &&
//                 quantity >= findSize(bottomSizing, innerItem.bottomSizing) &&
//                 quantity >= findSize(topSizing, innerItem.topSizing)));
//           if (isRegulerOrder) {
//             newItems.push(item);
//           } else {
//             newPreItems.push(item);
//           }
//         });
//       });
//       console.log({ distrinctProduct, newItems, newPreItems });
//     }
//   }, [allProduct]);

//   // bulk add to cart
//   // user need to be authenticated and if (allDistrincItem - shoppingCart > 0)
//   const [AddToCartMutation] = useMutation(ADD_TO_SHOPPING_CART);
//   const addToCart = async (newItem) => {
//     if (user.isAuthenticated) {
//       await AddToCartMutation({
//         variables: {
//           data: newItem,
//         },
//       });
//     }
//   };

//   // load shopping cart on authentication
//   useEffect(() => {
//     if (shoppingClassName && user?.isAuthenticated) {
//       loadShoppingCart();
//     }
//   }, [shoppingClassName]);

//   // combinding all data
//   useEffect(() => {
//     const combineItems = [...cartItem, ...preItems, ...shoppingCart];
//     const productIds = combineItems.map((item) => item?.product?._id);
//     loadProductForQuantity({ variables: { productIds: [...productIds] } });
//   }, [shoppingClassName, cartItem, preItems, shoppingCart.length]);

//   return (
//     <div
//       className={`shopping-cart-drawer ${shoppingClassName ? "overlay" : ""}`}
//       id="shopping-cart-drawer"
//     >
//       <div
//         ref={wrapRefShopping}
//         className={`shopping-cart-drawer-container ${
//           shoppingClassName ? "right_zero" : ""
//         }`}
//       >
//         <div
//           className="shopping-cart-inner-drawer"
//           style={{ marginBottom: "100px" }}
//         >
//           {/* <CartItems/> */}
//           {/* <Login/> */}
//           {cartLogin === CART_REDIRECT ? (
//             handleRenderComponent({
//               redirectForMobile,
//               openShoppingCart,
//               shoppingCart,
//               cartLoading,
//             })
//           ) : (
//             <CheckoutLogin {...props} />
//           )}
//           {/* {handleRenderCheckoutComponent()} */}

//           {/* <!-- Shopping cart calculation --> */}
//           <div className="auth-shopping">
//             {!user.isAuthenticated && redirectForMobile === SHOPPING && (
//               <PrivateMenu toLogin={true} />
//             )}
//           </div>
//           {/* <!-- Shopping Cart List --> */}
//           {user.isAuthenticated && <PrivateMenu />}
//           {/* <!-- Shopping Cart ListEnd --> */}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default DrawerShopping;

// const findSize = (sizeName, sizeList = []) => {
//   let size = sizeList.find((item) => sizeName === item.size.name);
//   return size?.quantity || 0;
// };

// const getDistrinctProducts = (items) => {
//   const newItem = [];
//   items.map((item) => {
//     let flag = true;
//     const { product, topSizing, bottomSizing } = item || {};
//     newItem.map((innerItem) => {
//       if (
//         (product._id === innerItem.product?._id &&
//           innerItem.product?.isAccessories) ||
//         (product._id === innerItem.product?._id &&
//           !innerItem.product?.isAccessories &&
//           topSizing === innerItem.topSizing &&
//           bottomSizing === innerItem.bottomSizing)
//       ) {
//         // item.quantity = item.quantity + 1;
//         flag = false;
//       }
//     });
//     if (flag) {
//       newItem.push(item);
//     }
//   });
//   return newItem;
// };

// const handleRenderComponent = ({
//   redirectForMobile,
//   openShoppingCart,
//   shoppingCart,
//   cartLoading,
// }) => {
//   switch (redirectForMobile) {
//     case LOGIN:
//       return <LoginShopping />;
//     case SHOPPING:
//       return (
//         <CartItems
//           openShoppingCart={openShoppingCart}
//           shoppingCart={shoppingCart}
//           cartLoading={cartLoading}
//         />
//       );
//     case FORGOT_PASSWORD:
//       return <ForgotPasswordShopping />;
//     case CREATE_ACCOUNT:
//       return <RegisterShopping />;
//     default:
//       return <CartItems openShoppingCart={openShoppingCart} />;
//   }
// };
