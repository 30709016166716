import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { useDispatch } from "react-redux";
import {
  CART_REDIRECT,
  CREATE_ACCOUNT,
  DRAWER_BUTTON,
  FORGOT_PASSWORD,
  loginBeforeCart,
  loginGuestUser,
  loginUser,
  onRediret,
  onSetDrawer,
  SHOPPING_DRAWER,
} from "../../store/modules";
import Button from "../Shared/Button";
import {
  ADD_NEW_SUBSCRIBER,
  CHECK_GUEST_EMAIL,
  USER_LOGIN,
} from "../../graphql/modules";
import {
  errorNotify,
  SHOPPING_ID,
  successNotify,
  warnNotify,
} from "../../util";
import { history } from "../../routes/AppRouter";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const CheckoutLogin = ({ ...props }) => {
  const dispatch = useDispatch();
  const handleRedirect = (set) => {
    console.log(set);
  };

  const [subscriberCheck, setSubscriberCheck] = useState(false);

  const orderType = useSelector((state) => state.orderType);

  // input state initialize
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [guestEmail, setGuestEmail] = useState("");
  const [guestEmailError, setGuestEmailError] = useState("");

  // login api
  const [handleLogin, { loading }] = useMutation(USER_LOGIN);
  const handleSubmit = async () => {
    try {
      const {
        data: { Login },
      } = await handleLogin({
        variables: {
          email: email.toLocaleLowerCase(),
          password: password,
        },
      });
      if (Login.success) {
        successNotify(Login.message);
        dispatch(loginUser(Login));
      } else {
        warnNotify(Login.message);
      }
    } catch (error) {}
  };

  // handle input
  const handleInput = (e) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!re.test(e.target.value.toLowerCase())) {
      setGuestEmailError("Please enter a valid email, e.g. name@domain.com");
    } else {
      setGuestEmailError("");
    }
    setGuestEmail(e.target.value);
  };

  // go to cart
  const handleBack = () => {
    dispatch(loginBeforeCart({ type: CART_REDIRECT }));
  };

  // go to checkout for guest user
  const guestUserCheckout = () => {
    dispatch(loginGuestUser(guestEmail));
    history.push(`/checkout?type=${orderType}`);
  };

  // subscriber api call function
  const [AddSubscriberMutation] = useMutation(ADD_NEW_SUBSCRIBER);
  const addSubscriber = async () => {
    try {
      const {
        data: { AddSubscriber },
      } = await AddSubscriberMutation({
        variables: {
          email: guestEmail.toLowerCase(),
        },
      });
      if (AddSubscriber.success) {
        console.log("ok");
      } else {
        warnNotify(AddSubscriber.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // check user exist or not
  const [CheckGuestEmailMutation, { loading: guestLoading }] =
    useMutation(CHECK_GUEST_EMAIL);
  const checkUserExistWithEmail = async () => {
    try {
      const {
        data: { IsGuestUser },
      } = await CheckGuestEmailMutation({
        variables: {
          email: guestEmail.toLowerCase(),
        },
      });
      if (IsGuestUser.success) {
        guestUserCheckout();
        if (subscriberCheck) {
          addSubscriber();
        }
      } else {
        errorNotify(IsGuestUser.message);
      }
    } catch (error) {}
  };

  // login button disable
  const disable = !email || !password;

  return (
    <div>
      <button
        style={{
          padding: "0",
          backgroundColor: "transparent",
          border: "0",
          textDecoration: "underline",
          textDecorationColor: "#3d3d3d",
          color: "#3d3d3d",
        }}
        onClick={handleBack}
        id={SHOPPING_ID}
      >
        Return to cart
      </button>
      <div className="login-title">
        <h5>Customer sign in</h5>
      </div>
      <form>
        <div className="mb-3">
          <label htmlFor="exampleInputEmail1" className="form-label">
            Email*
          </label>
          <input
            type="email"
            className="form-control cmn-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="exampleInputPassword1" className="form-label">
            Password*
          </label>
          <input
            type="password"
            className="form-control cmn-control"
            id="exampleInputPassword1"
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="row g-3">
          <div className="col">
            <div className="form-group form-check d-flex">
              <input
                type="checkbox"
                className="form-check-input check_mod"
                id="exampleCheck1"
                style={{ marginRight: "5px" }}
              />
              <label className="form-check-label" htmlFor="exampleCheck1">
                Remember Me
              </label>
            </div>
          </div>
          <div className="col forget_p text-end">
            <button
              style={{
                border: "none",
                background: "transparent",
                textDecoration: "underline",
              }}
              onClick={(e) => {
                e.preventDefault();
                history.push("/forgot-password");
                dispatch(onSetDrawer(SHOPPING_DRAWER, false, DRAWER_BUTTON));
              }}
            >
              Forget Password?
            </button>
          </div>
        </div>
        <Button
          btnText="Login"
          loading={loading}
          disabled={disable}
          onClick={handleSubmit}
          style={{ marginBottom: "0" }}
        />
      </form>
      <hr />
      <div className="no-account">
        <div className="no-acc-title">
          <h5>Guest checkout</h5>
        </div>
        <div className="mb-3">
          <label htmlFor="exampleInputEmail1" className="form-label">
            Email*
          </label>
          <div id="emailHelp" className="guest_mail_info">
            Please enter an email where you will receive your order information.
          </div>
          <input
            type="email"
            className="form-control cmn-control"
            value={guestEmail.toLowerCase()}
            onChange={handleInput}
          />

          {guestEmailError && (
            <div className="invalid-feedback d-flex align-items-center">
              <span className="mr-1">
                <img src="/images/warning.svg" alt="" />
              </span>
              {guestEmailError}
            </div>
          )}
        </div>

        <div className="mb-3 form-check _newsletter">
          <input
            type="checkbox"
            className="form-check-input guest_mail_info_check"
            id="guest_mail_info_check"
            checked={subscriberCheck}
            onChange={(e) => setSubscriberCheck(e.target.checked)}
          />
          <label
            className="form-check-label sub_to_news"
            htmlFor="guest_mail_info_check"
          >
            Subscribe to our newsletter
          </label>
        </div>

        <div id="emailHelp" className="guest_mail_info">
          By checking this box, you accept the term of our{" "}
          <span>
            <Link to="/faq">Privacy Policy</Link>
          </span>
        </div>

        <Button
          btnText="Continue"
          btnClass="create-now-btn"
          style={{ marginTop: "2px" }}
          loading={guestLoading}
          onClick={checkUserExistWithEmail}
          disabled={!guestEmail || guestEmailError}
        />
      </div>
    </div>
  );
};

export default CheckoutLogin;
