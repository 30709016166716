import React from "react";
import { getFormatAddress } from "../../util";

export const DeliveryAddress = ({ orderDetails = {} }) => {
  return (
    <div className="orderdetails_container4">
      <div className="orderdetails_head4">
        <p style={{ fontWeight: "bold" }}>Delivery Address</p>
      </div>
      <hr />
      <div className="orderdetails_content">
        <p style={{ fontWeight: "bold" }}>
          {(orderDetails?.shippingAddress?.firstname || "") +
            " " +
            (orderDetails?.shippingAddress?.lastname || "")}
        </p>
        <span>
          <p className="text-uppercase">
            {getFormatAddress(orderDetails?.shippingAddress).first}{" "}
          </p>
          <p className="text-uppercase">
            {getFormatAddress(orderDetails?.shippingAddress).second}{" "}
          </p>
          <p>{countryName(orderDetails?.shippingAddress?.country)}</p>
          <p>Phone Number: {orderDetails?.shippingAddress?.phone || ""}</p>
        </span>
      </div>
    </div>
  );
};

const countryName = (country) => {
  switch (country) {
    case "AU":
      return "Australia";
    case "CA":
      return "Canada";
    default:
      return "United States";
  }
};
