export const shipmentType = {
  US: [
    {
      title: "1 Business Day",
      lowDuration: "$50.00 by UPS Next Day Air",
      duration: "$50.00 by UPS Next Day Air",
      lowCharge: "50.00",
      regularCharge: "50.00",
      // type: shippingType.oneDayAir,
      country: "US",
    },
    {
      title: "2 Business Days",
      lowDuration: "$35.00 by UPS 2nd Day Air",
      duration: "$35.00 by UPS 2nd Day Air",
      lowCharge: "35.00",
      regularCharge: "35.00",
      // type: shippingType.twoDayAir,
      country: "US",
      // minWidth:"176px"
    },
    {
      title: "2-7 Business Days",
      lowDuration: "Free by UPS Ground",
      duration: "$10.00 by UPS Ground",
      lowCharge: "0.00",
      regularCharge: "10.00",
      // type: shippingType.standard,
      country: "US",
      // minWidth:"176px"
    },
  ],
  CA: [
    {
      title: "1 Business Day by Noon",
      lowDuration: "USD 45.00 by UPS Worldwide Express",
      duration: "USD 45.00 by UPS Worldwide Express",
      lowCharge: "45.00",
      regularCharge: "45.00",
      // type: shippingType.standard,
      country: "CA",
    },
    {
      title: "1 Business Day by End of Day",
      lowDuration: "USD 35.00 by UPS Worldwide Saver",
      duration: "USD 35.00 by UPS Worldwide Saver",
      lowCharge: "35.00",
      regularCharge: "35.00",
      // type: shippingType.twoDayAir,
      country: "CA",
    },
    {
      title: "2 Business Days",
      duration: "USD 30.00 by UPS 2nd Day Air",
      lowDuration: "USD 30.00 by UPS 2nd Day Air",
      lowCharge: "30.00",
      regularCharge: "30.00",
      // type: shippingType.oneDayAir,
      country: "CA",
    },
    {
      title: "5 Business Days",
      lowDuration: "Free by UPS Standard",
      duration: "USD 15.00 by UPS Standard",
      lowCharge: "00.00",
      regularCharge: "15.00",
      // type: shippingType.oneDayAir,
      country: "CA",
    },
  ],
  AU: [
    {
      title: "5-7 Business Days",
      lowDuration: "Free by DHL",
      duration: "USD 25.00 by DHL",
      lowCharge: "00.00",
      regularCharge: "25.00",
      // type: shippingType.standard,
      country: "AU",
    },
  ],
};

export const shipmentTypePre = {
  US: [
    {
      title: "1-2 weeks",
      // lowDuration: "30.00 USD / outfit by DHL",
      // duration: "30.00 USD / outfit by DHL",
      lowCharge: "0.00",
      unit: true,
      regularCharge: "0.00",
      // type: shippingType.standard,
      country: "US",
      pre: true,
      explain:
      "10-15 days (made to order/ pre order)  The outfit is currently out of the stock and we will need about 10-15 days to make them then ship. If you need the outfit by a certain date, please email customer service to confirm.",
    // minWidth:"176px"
      // minWidth:"176px"
    },
    // {
    //   title: "3-5 weeks (Regular Pre Order)",
    //   lowDuration: "Free by UPS",
    //   duration: "Free by UPS",
    //   lowCharge: "0",
    //   regularCharge: "0",
    //   // type: shippingType.twoDayAir,
    //   country: "US",
    //   pre: true,
    //   explain: "We will need 3-5 weeks to make the order and then ship from our warehouse.  If you would like to get an outfit by any particular date, please email to ask."
    //   // minWidth:"176px"
    // },
  ],
  CA: [
    {
      title: "1-2 weeks",
      // lowDuration: "0.00 USD / outfit by DHL",
      // duration: "30.00 USD / outfit by DHL",
      lowCharge: "0.00",
      // unit: true,
      regularCharge: "0.00",
      // type: shippingType.standard,
      country: "CA",
      pre: true,
      explain:
        "10-15 days (made to order/ pre order)  The outfit is currently out of the stock and we will need about 10-15 days to make them then ship. If you need the outfit by a certain date, please email customer service to confirm.",
      // minWidth:"176px"
    },
    // {
    //   title: "3-5 weeks",
    //   lowDuration: "Free by UPS",
    //   duration: "Free by UPS",
    //   lowCharge: "0",
    //   regularCharge: "0",
    //   // type: shippingType.twoDayAir,
    //   country: "CA",
    //   pre: true,
    //   explain: "We will need 3-5 weeks to make the order and then ship from our warehouse.  If you would like to get an outfit by any particular date, please email to ask."
    //   // minWidth:"176px"
    // },
  ],
  AU: [
    {
      title: "1-2 weeks",
      // lowDuration: "30.00 USD / outfit by DHL",
      // duration: "30.00 USD / outfit by DHL",
      lowCharge: "0.00",
      // unit: true,
      regularCharge: "0.00",
      // type: shippingType.standard,
      country: "AU",
      pre: true,
      explain:
        "10-15 days (made to order/ pre order)  The outfit is currently out of the stock and we will need about 10-15 days to make them then ship. If you need the outfit by a certain date, please email customer service to confirm.",
      // minWidth:"176px"
    },
    // {
    //   title: "3-5 weeks",
    //   lowDuration: "Free by FedEx",
    //   duration: "Free by FedEx",
    //   lowCharge: "0",
    //   regularCharge: "0",
    //   // type: shippingType.twoDayAir,
    //   country: "AU",
    //   pre: true,
    //   explain: "We will need 3-5 weeks to make the order and then ship from our warehouse.  If you would like to get an outfit by any particular date, please email to ask."
    //   // minWidth:"176px"
    // },
  ],
};

export const shipmentTypeAll = {
  US: [
    {
      title: "1 Business Day Once Pre Order is Ready",
      lowDuration: "$50.00 by UPS Next Day Air",
      duration: "$50.00 by UPS Next Day Air",
      lowCharge: "50.00",
      regularCharge: "50.00",
      // type: shippingType.standard,
      country: "US",
      // minWidth:"176px"
    },
    {
      title: "2 Business Days Once Pre Order is Ready",
      lowDuration: "$35.00 by UPS 2nd Day Air",
      duration: "$35.00 by UPS 2nd Day Air",
      lowCharge: "35",
      regularCharge: "35",
      country: "US",
    },
    {
      title: "2-7 Business Days Once Pre Order is Ready",
      lowDuration: "Free by UPS Ground",
      duration: "$10.00 by UPS Ground",
      lowCharge: "0",
      regularCharge: "10",
      country: "US",
    },
  ],
  CA: [
    {
      title: "1 Business Day Once Pre Order is Ready",
      lowDuration: "$50.00 by UPS Next Day Air",
      duration: "$50.00 by UPS Next Day Air",
      lowCharge: "50.00",
      regularCharge: "50.00",
      // type: shippingType.standard,
      country: "CA",
      // minWidth:"176px"
    },
    {
      title: "2 Business Days Once Pre Order is Ready",
      lowDuration: "$35.00 by UPS 2nd Day Air",
      duration: "$35.00 by UPS 2nd Day Air",
      lowCharge: "35",
      regularCharge: "35",
      country: "CA",
    },
    {
      title: "2-7 Business Days Once Pre Order is Ready",
      lowDuration: "Free by UPS Ground",
      duration: "USD 15.00 by UPS Ground",
      lowCharge: "0",
      regularCharge: "15",
      country: "CA",
    },
  ],
  AU: [
    {
      title: "1 Business Day Once Pre Order is Ready",
      lowDuration: "$50.00 by UPS Next Day Air",
      duration: "$50.00 by UPS Next Day Air",
      lowCharge: "50.00",
      regularCharge: "50.00",
      // type: shippingType.standard,
      country: "AU",
      // minWidth:"176px"
    },
    {
      title: "2 Business Days Once Pre Order is Ready",
      lowDuration: "$35.00 by UPS 2nd Day Air",
      duration: "$35.00 by UPS 2nd Day Air",
      lowCharge: "35",
      regularCharge: "35",
      country: "AU",
    },
    {
      title: "2-7 Business Days Once Pre Order is Ready",
      lowDuration: "Free by UPS Ground",
      duration: "USD 25.00 by UPS Ground",
      lowCharge: "0",
      regularCharge: "25",
      country: "AU",
    },
  ],
};
