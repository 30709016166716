//Constant

// initial state
const initialState = {
  shippingMethod: {
    shippingCherge: 0,
  },
  shippingMethodPre: {
    shippingCherge: 0,
  },
};

export const setOrderDetails = (state = initialState, action) => {
  //   state = [...payload];
  const { payload = {}, type } = action;
  switch (type) {
    case "Method": {
      const shippingMethod = {
        ...state.shippingMethod,
        shippingDurationText: payload.shippingDurationText,
        shippingCherge: parseFloat(payload.shippingCherge),
        shippingType: payload.shippingType,
        pre: payload?.pre || false
      };
      state = { ...state, shippingMethod };
      return state;
    }
    case "MethodPre": {
      const shippingMethodPre = {
        ...state.shippingMethodPre,
        shippingDurationText: payload.shippingDurationText,
        shippingCherge: parseFloat(payload.shippingCherge),
        shippingType: payload.shippingType,
        pre: payload?.pre || false
      };
      state = { ...state, shippingMethodPre };
      return state;
    }

    default:
      return state;
  }
};

export const setShippingMethod = (payload = []) => {
  return {
    type: "Method",
    payload,
  };
};
export const setShippingMethodPreOrder = (payload = []) => {
  return {
    type: "MethodPre",
    payload,
  };
};

