import gql from "graphql-tag";

export const FETCH_FAVOURITE = gql`
  query FetchFavouriteList {
    FetchFavouriteList {
      code
      success
      message
      count
      result {
        createdAt
        topSizing
        bottomSizing
        topColor
        topColorName
        bottomColor
        bottomColorName
        topSizing
        bottomSizing
        product {
          _id
          name
          price
          topPrice
          bottomPrice
          isAccessories
          category {
            _id
            sizeType
          }
          subcategory {
            _id
            sizeType
          }
          gallery
          colors {
            topColor
            bottomColor
            topGallery
            gallery
            bottomGallery
          }
          isAvailable
          status
        }
        style
      }
    }
  }
`;

export const ADD_FAVOURITE = gql`
  mutation AddProductAsFavourite($inputData: FavouriteInput) {
    AddProductAsFavourite(inputData: $inputData) {
      code
      success
      message
    }
  }
`;

export const DELETE_FAVOURITE = gql`
  mutation DeleteProductFromFavourite($productId: ID) {
    DeleteProductFromFavourite(productId: $productId) {
      code
      success
      message
    }
  }
`;
