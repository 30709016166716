import React, { Fragment } from "react";
import { AfterPaylogo, InfoIco } from "../Shared";
import { Tooltip } from "antd";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { CART_LOGIN, loginBeforeCart, setOrderType } from "../../store/modules";
import { SHOPPING_ID } from "../../util";

export const CartSummary = ({
  cartSubtotal,
  purchaseDiscount,
  user,
  orderType,
  openShoppingCart,
}) => {
  const dispatch = useDispatch();
  const loginForPurchase = () => {
    dispatch(loginBeforeCart({ type: CART_LOGIN }));
    dispatch(setOrderType({ type: orderType }));
  };
  return (
    <Fragment>
      <div className="shopping-cart-drawer-calculation-list">
        <div className="row" style={{ marginBottom: "10px" }}>
          <div className="col-12">
            <hr />
          </div>
          <div className="col-md-6 col_50_50">
            <div className="sub-total mt-3">
              <p>Sub-total</p>
            </div>
          </div>
          <div className="col-md-6 col_50_50">
            <div className="sub-calculation-value mt-3">
              <p>{parseFloat(cartSubtotal).toFixed(2)} USD</p>
            </div>
          </div>
          <div className="col-md-6 col_65">
            <div className="sub-total">
              <p>Shipping</p>
            </div>
          </div>
          <div className="col-md-6 col_35">
            <div className="sub-calculation-value">
              <p>{cartSubtotal >= 100 ? "Free" : "10 USD"}</p>
            </div>
          </div>
          {purchaseDiscount > 0 && (
            <Fragment>
              <div className="col-md-6 col_65">
                <div className="sub-total">
                  <p>Discount on purchase</p>
                </div>
              </div>
              <div className="col-md-6 col_35">
                <div className="sub-calculation-value">
                  <p style={{ color: "red" }}>-{purchaseDiscount} USD</p>
                </div>
              </div>
            </Fragment>
          )}
          <div className="col-md-4 col_20">
            <div className="sub-total">
              <p>Estimated Tax</p>
            </div>
          </div>
          <div className="col-md-8 col_80">
            <div className="sub-calculation-value tx">
              <p>Calculated during checkout</p>
            </div>
          </div>

          <div className="col-md-6 col_65">
            <div className="sub-total">
              <p>Estimated total</p>
            </div>
          </div>
          <div className="col-md-6 col_35">
            <div className="sub-calculation-value">
              <p>
                {parseFloat(
                  cartSubtotal +
                    (cartSubtotal >= 100 ? 0 : 10) -
                    purchaseDiscount
                ).toFixed(2)}{" "}
                USD
              </p>
            </div>
          </div>
          <div className="col-12">
            <p className="text-start" style={{ marginBottom: "10px" }}>
              or 4 payments of USD{" "}
              {parseFloat(
                (cartSubtotal +
                  ((cartSubtotal >= 100 ? 0 : 10) - purchaseDiscount)) /
                  4
              ).toFixed(2)}{" "}
              with
              <span className="ms-1">
                {" "}
                <AfterPaylogo width="70" />
                <span className="mx-2">or</span>
                <img src="/images/klarna.svg" alt="" width="50"/>
              </span>
              <Tooltip title="Pay later in 4 installments. The first payment is taken when the order is processed and the remaining 3 are automatically taken at scheduled intervals">
                <span className="ms-1">
                  <InfoIco width="12" />
                </span>
              </Tooltip>
            </p>
          </div>
        </div>
        {user.isAuthenticated ? (
          <Link to={`/checkout?type=${orderType}`}>
            <button
              onClick={openShoppingCart}
              className="btn btn-common "
              style={{ margin: "0" }}
              id={SHOPPING_ID}
            >
              Checkout
            </button>
          </Link>
        ) : (
          <button
            onClick={loginForPurchase}
            className="btn btn-common"
            style={{ marginTop: "0" }}
            id={SHOPPING_ID}
          >
            Checkout
          </button>
        )}
      </div>
      {user?.isAuthenticated && <hr />}
    </Fragment>
  );
};
