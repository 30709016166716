import React from "react";
import { svgIcon } from "../util";

const SuspenseFallback = () => {
  return (
    <div
      style={{
        background: "rgba(255, 244, 252, 1)",
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div style={{ width: "80px" }}>{svgIcon}</div>
    </div>
  );
};

export default SuspenseFallback;
