import { getAuthData, getCartItem, getGuestUser, setAuthData, setCartItem, setGuestUser } from "../util/session";

export const saveState = state => {
  try {
    // Parsing auth data from Redux store
    let stateFilter = state;
    setAuthData(stateFilter.auth);
    setCartItem(stateFilter.cart);
    setGuestUser(stateFilter.guest)
  } catch (err) {
    // Ignore write error
  }
};

/* Use an IIFE to export the persisted state in a variable */
export const persistedState = (() => {
  try {
    const auth = getAuthData();
    const cart = getCartItem();
    const guest = getGuestUser();
    // if (Object.keys(auth).length === 0) return undefined;
    return {
      auth,
      cart,
      guest,
    };
  } catch (err) {
    return undefined;
  }
})();
