import { useQuery } from "@apollo/react-hooks";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ADMIN_EMAIL } from "../../config/constants";
import {
  ANNOUNCEMENT_SUBSCRIPTION,
  APP_SETTINGS_QUERY,
  MAINTENANCE_SUBSCRIPTION,
} from "../../graphql/modules";

const Announcement = ({}) => {
  const [showAnnounce, setShowAnnounce] = useState(true);

  const { user } = useState((state) => state?.auth);

  const history = useHistory();

  const { data, refetch, subscribeToMore } = useQuery(APP_SETTINGS_QUERY);

  const announce = data?.FetchAppSettings?.announcement || {};
  const isInMaintenance =
    user?.email !== ADMIN_EMAIL && data?.FetchAppSettings?.isInMaintenance;

  const fetchMoreForAnnounce = () => {
    subscribeToMore({
      document: ANNOUNCEMENT_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        refetch();
        console.log(subscriptionData);
      },
    });
  };

  const fetchMoreForMaintenance = () => {
    subscribeToMore({
      document: MAINTENANCE_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        refetch();
        console.log(subscriptionData);
      },
    });
  };

  // const fetchMoreForCartQuanity = () => {
  //   subscribeToMore({
  //     document: CART_QUANTITY_LIMIT,
  //     updateQuery: (prev, { subscriptionData }) => {
  //       refetch();
  //       // console.log(subscriptionData);
  //     },
  //   });
  // };

  useEffect(() => {
    fetchMoreForAnnounce();
    fetchMoreForMaintenance();
    // fetchMoreForCartQuanity();
  }, []);

  const isShowAnnounce =
    announce?.isOn &&
    showAnnounce &&
    history.location.pathname !== "/checkout" &&
    history.location.pathname !== "/order-confirmation";

  return (
    <div>
      {isShowAnnounce && !isInMaintenance && (
        <section className="announcement align-items-center" id="lempor_rasel">
          <div className="container_standard">
            <div className="row">
              <div className="d-flex align-items-center">
                <div
                  style={{ marginRight: "20px", display: "flex" }}
                  onClick={() => setShowAnnounce(false)}
                >
                  {crossSvg}
                </div>

                <div className="w-100 text-end" style={{ overflow: "hidden" }}>
                  <div
                    className="moving-announce"
                    dangerouslySetInnerHTML={{ __html: announce?.msg }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default Announcement;

const crossSvg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.82"
    height="17.82"
    viewBox="0 0 17.82 17.82"
    style={{ width: "12px", cursor: "pointer" }}
  >
    <path
      id="cross"
      d="M10.8,104.91,16.512,99.2l1.178-1.178a.446.446,0,0,0,0-.63l-1.26-1.26a.446.446,0,0,0-.63,0L8.91,103.02,2.021,96.13a.446.446,0,0,0-.63,0L.13,97.391a.446.446,0,0,0,0,.63l6.89,6.89L.13,111.8a.446.446,0,0,0,0,.63l1.26,1.26a.446.446,0,0,0,.63,0L8.91,106.8l5.712,5.712L15.8,113.69a.446.446,0,0,0,.63,0l1.26-1.26a.446.446,0,0,0,0-.63Z"
      transform="translate(0 -96)"
      fill="#3d3d3d"
    />
  </svg>
);
