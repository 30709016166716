import React from "react";

const SinglePolicy = ({item}) => {
  return (
    <div className="accordion-item accordion__item">
      <h2 className="accordion-header" id={`heading${item._id}`}>
        <button
          className="accordion-button accordion__button collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#collapse${item._id}`}
          aria-expanded="true"
          aria-controls={`collapse${item._id}`}
        >
          {item?.title ?? ''}
        </button>
      </h2>
      <div
        id={`collapse${item._id}`}
        className="accordion-collapse collapse"
        aria-labelledby={`heading${item._id}`}
        data-bs-parent="#accordionExample"
      >
        <div className="accordion-body accordion__body">
        <div
            dangerouslySetInnerHTML={{
              __html: item?.desc,
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default SinglePolicy;