import React, { useState } from "react";
// import "intl-tel-input/build/css/intlTelInput.css";
import { Button } from "../Shared";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import moment from "moment";
import { LOGIN, onRediret } from "../../store/modules";
import { useDispatch } from "react-redux";
import { useMutation } from "@apollo/react-hooks";
import { USER_SIGNUP } from "../../graphql/modules/auth";
import { successNotify, USER_ID, warnNotify } from "../../util";
import { Link } from "react-router-dom";
const Register = () => {
  // registration input state initialize
  const [userData, setUserData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    password: "",
    birthDate: "",
  });
  const [err, setErr] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    password: "",
    birthDate: "",
    cpassword: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);
  const [checked, setChecked] = useState(false);

  // initilize dispatch hook
  const dispatch = useDispatch();

  // confirm password state
  const [cpassword, setCpassword] = useState("");

  // days based on month
  const dayArray = () => {
    let days = [];
    for (var i = 1; i <= months[0].day; i++) {
      days.push(i);
    }
    return days;
  };

  // 56 years initilize
  const yearsArray = () => {
    const currentYear = Number(new Date().getFullYear()) - 15;
    let years = [];
    for (var i = currentYear - 56; i <= currentYear; i++) {
      years.push(i);
    }
    return years;
  };

  // handle confirm pass

  const handleConfirmPass = (e) => {
    setCpassword(e.target.value);
    if (e.target.value !== userData.password) {
      setErr({ ...err, cpassword: "Password doesn't match!" });
    } else {
      setErr({ ...err, cpassword: "" });
    }
  };

  // onChange input
  const handleOnchange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
    var mediumPasswordChecking = new RegExp(
      "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})"
    );
    var nameChecking = new RegExp("^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$");
    if (name === "firstname") {
      if (value) {
        if (!nameChecking.test(value)) {
          setErr({
            ...err,
            firstname: "No numbers or special characters for the name",
          });
        } else {
          setErr({ ...err, firstname: "" });
        }
      } else {
        setErr({ ...err, firstname: "" });
      }
    }
    if (name === "lastname") {
      if (value) {
        if (!nameChecking.test(value)) {
          setErr({
            ...err,
            lastname: "No numbers or special characters for the name",
          });
        } else {
          setErr({ ...err, lastname: "" });
        }
      } else {
        setErr({ ...err, lastname: "" });
      }
    }
    if (name === "password") {
      if (value) {
        if (!mediumPasswordChecking.test(value) || value.length < 6) {
          setErr({
            ...err,
            password:
              "Please enter 6 characters minimum with the combination of numbers and letters.",
          });
        } else {
          setErr({ ...err, password: "" });
        }
      } else {
        setErr({ ...err, password: "" });
      }
    }
    if (name === "email") {
      const emailValidator =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (value) {
        if (!emailValidator.test(String(value).toLowerCase())) {
          setErr({
            ...err,
            email: "Please enter a valid email, e.g. name@domain.com",
          });
        } else {
          setErr({ ...err, email: "" });
        }
      } else {
        setErr({ ...err, email: "" });
      }
    }
  };

  //birthdate convert to milliseconds
  const [birth, setBirth] = useState({
    month: 1,
    day: 1,
    year: 1950,
  });

  const handleBirthday = (e) => {
    const { name, value } = e.target;
    setBirth({ ...birth, [name]: value });
    const millisecondsDay = moment(
      `${birth.month} ${birth.day} ${value}`,
      "MM DD YYYY"
    ).format("x");
    setUserData({ ...userData, birthDate: millisecondsDay });
  };
  const handleBirthmonth = (e) => {
    const { name, value } = e.target;
    setBirth({ ...birth, [name]: value });
    const millisecondsDay = moment(
      `${birth.month} ${birth.day} ${value}`,
      "MM DD YYYY"
    ).format("x");
    setUserData({ ...userData, birthDate: millisecondsDay });
  };
  const handleBirthyear = (e) => {
    const { name, value } = e.target;
    setBirth({ ...birth, [name]: value });
    const millisecondsDay = moment(
      `${birth.month} ${birth.day} ${value}`,
      "MM DD YYYY"
    ).format("x");
    setUserData({ ...userData, birthDate: millisecondsDay });
  };

  // submit create account
  const [onCreateAccount, { loading }] = useMutation(USER_SIGNUP);
  const handleSubmit = async () => {
    const userInput = {...userData, email: userData.email.toLowerCase()}
    try {
      const {
        data: { Register },
      } = await onCreateAccount({
        variables: {
          userInput,
        },
      });
      if (Register.success) {
        successNotify(Register.message);
        handleBack();
      } else {
        warnNotify(Register.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //go login
  const handleBack = () => {
    dispatch(onRediret({ type: LOGIN }));
  };
  // disable based on input value
  const disabled =
    !userData.email ||
    !userData.birthDate ||
    !userData.firstname ||
    !userData.lastname ||
    !userData.password ||
    !userData.phone ||
    userData.password !== cpassword ||
    err.firstname ||
    err.lastname ||
    err.password ||
    err.phone ||
    !checked;

  return (
    <div className="login-form-container">
      <div className="user-inner-drawer mb-5">
        <div className="login-title">
          <button
            style={{
              padding: "0",
              backgroundColor: "transparent",
              border: "0",
              textDecoration: "underline",
              textDecorationColor: "#3d3d3d",
              color: "#3d3d3d",
            }}
            onClick={handleBack}
            id={USER_ID}
          >
            Return to login
          </button>
          <h5>Sign Up</h5>
        </div>
        <form action="">
          <div className="mb-3">
            <label htmlFor="firstname" className="form-label" >
              First Name*
            </label>
            <input
              type="text"
              className="form-control cmn-control"
              id="firstname"
              name="firstname"
              onChange={handleOnchange}
              value={userData.firstname}
            />
            {err.firstname && (
              <div className="invalid-feedback d-flex align-items-center">
                <span className="mr-1">
                  <img src="/images/warning.svg" alt="" />
                </span>
                {err.firstname}
              </div>
            )}
          </div>

          <div className="mb-3">
            <label htmlFor="lastname" className="form-label">
              Last Name*
            </label>
            <input
              type="text"
              className="form-control cmn-control"
              id="lastname"
              name="lastname"
              onChange={handleOnchange}
              value={userData.lastname}
            />
            {err.lastname && (
              <div className="invalid-feedback d-flex align-items-center">
                <span>
                  <img src="/images/warning.svg" alt="" />
                </span>
                {err.lastname}
              </div>
            )}
          </div>

          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Email*
            </label>
            <input
              type="email"
              className="form-control cmn-control"
              id="email"
              name="email"
              onChange={handleOnchange}
              value={userData?.email?.toLowerCase()}
            />
            {err.email && (
              <div className="invalid-feedback d-flex align-items-center">
                <span className="mr-1">
                  <img src="/images/warning.svg" alt="" />
                </span>
                {err.email}
              </div>
            )}
          </div>

          <div className="mb-3">
            <label htmlFor="phone" className="form-label">
              Phone Number*
            </label>
            <br />
            <IntlTelInput
              containerClassName="intl-tel-input w-100"
              inputClassName="form-control"
              onPhoneNumberChange={(status, value, countryData, number, id) => {
                setUserData({ ...userData, phone: number });
                const phoneValidator =
                  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
                if (!phoneValidator.test(value)) {
                  setErr({ ...err, phone: "Phone number is not valid!" });
                } else {
                  setErr({ ...err, phone: "" });
                }
              }}
            />

            <div id="emailHelp" className="form-text">
              Change country code by taping the flag.
            </div>
            {err.phone && (
              <div className="invalid-feedback d-flex align-items-center">
                <span>
                  <img src="/images/warning.svg" alt="" />
                </span>
                {err.phone}
              </div>
            )}
          </div>

          <div className="mb-3">
            <div className="row">
              <div className="col-md-6">
                <label htmlFor="password" className="form-label">
                  Password*
                </label>
              </div>
              <div className="col-md-6">
                <label
                  htmlFor="password_show"
                  className="form-label password_show"
                >
                  <p
                    onClick={() => setShowPassword(!showPassword)}
                    className="show_pass"
                  >
                    {showPassword ? "Hide" : "Show"}
                  </p>
                </label>
              </div>
            </div>
            <input
              type={showPassword ? "text" : "password"}
              className="form-control cmn-control"
              id="password"
              onChange={handleOnchange}
              name="password"
              value={userData.password}
            />
            {err.password && (
              <div className="invalid-feedback d-flex align-items-top">
                <span>
                  <img src="/images/warning.svg" alt="" />
                </span>
                {err.password}
              </div>
            )}
          </div>

          <div className="mb-3">
            <div className="row">
              <div className="col-md-6">
                <label htmlFor="cpassword" className="form-label">
                  Confirm Password*
                </label>
              </div>
              <div className="col-md-6">
                <label
                  htmlFor="password_show"
                  className="form-label password_show"
                >
                  <p
                    onClick={() => setShowCPassword(!showCPassword)}
                    className="show_pass"
                  >
                    {showCPassword ? "Hide" : "Show"}
                  </p>
                </label>
              </div>
            </div>
            <input
              type={showCPassword ? "text" : "password"}
              className="form-control cmn-control"
              id="cpassword"
              name="cpassword"
              onChange={handleConfirmPass}
              value={cpassword}
            />
            {err.cpassword && (
              <div className="invalid-feedback d-flex align-items-center">
                <span>
                  <img src="/images/warning.svg" alt="" />
                </span>
                {err.cpassword}
              </div>
            )}
          </div>

          <div className="row">
            <div className="col-12">
              <div className="mb-3">
                <label htmlFor="confirm_password" className="form-label">
                  Birthday*
                </label>
                <br />

                <label htmlFor="confirm_password" className="form-label">
                  Month
                </label>
                <select
                  className="form-select cmn-control selected_clr"
                  name="month"
                  onChange={handleBirthmonth}
                  required
                >
                  <option value="">Select Month</option>
                  {months.map((item) => (
                    <option className="selected_item" key={item.value} value={item.value}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col">
              <div className="mb-3">
                <label htmlFor="confirm_password" className="form-label">
                  Day
                </label>
                <select
                  className="form-select cmn-control selected_clr"
                  name="day"
                  onChange={handleBirthday}
                >
                  {dayArray().map((day) => (
                    <option key={day} value={day}>
                      {day}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col">
              <div className="mb-3">
                <label htmlFor="confirm_password" className="form-label">
                  Year
                </label>
                <select
                  className="form-select cmn-control selected_clr"
                  name="year"
                  onChange={handleBirthyear}
                > 
                  {yearsArray().map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="form-check">
            <input
              className="form-check-input agree_check"
              type="checkbox"
              defaultValue
              id="flexCheckChecked"
              onClick={() => setChecked(!checked)}
            />
            <label className="form-check-label" htmlFor="flexCheckChecked">
              I acknowledge that I have read the <Link to="/faq">Privacy policy</Link> and agree to the
              <Link to="/faq">&nbsp;term of use</Link>.
            </label>
          </div>

          {/* {!checked && (
            <div className="invalid-feedback d-flex align-items-top">
              <span>
                <img src="/images/warning.svg" alt="" />
              </span>
              Please agree the Privacy policy & term of use.
            </div>
          )} */}
          <Button
            btnText="Create Now"
            disabled={disabled}
            loading={loading}
            onClick={handleSubmit}
          />
        </form>
      </div>
    </div>
  );
};

const months = [
  { name: "January", value: "01", day: 31 },
  { name: "February", value: "02", day: 28 },
  { name: "March", value: "03", day: 31 },
  { name: "April", value: "04", day: 30 },
  { name: "May", value: "05", day: 31 },
  { name: "June", value: "06", day: 30 },
  { name: "July", value: "07", day: 31 },
  { name: "August", value: "08", day: 31 },
  { name: "September", value: "09", day: 30 },
  { name: "October", value: "10", day: 31 },
  { name: "November", value: "11", day: 30 },
  { name: "December", value: "12", day: 31 },
];

export default Register;
