import gql from "graphql-tag";

export const FILE_UPLOAD = gql`
  mutation FileUpload($file: Upload!) {
    SingleUpload(file: $file) {
      filename
      filesize
      imageLink
    }
  }
`;
