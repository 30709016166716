import gql from "graphql-tag";

export const FETCH_ADDRESS = gql`
  query FetchAddresses {
    FetchAddresses {
      code
      success
      message
      result {
        _id
        country
        state
        city
        zipCode
        address
        firstname
        lastname
        phone
        apertment
      }
    }
  }
`;

export const ADD_ADDRESS = gql`
  mutation AddAddress($data: AddressInput) {
    AddAddress(data: $data) {
      code
      success
      message
    }
  }
`;

export const EDIT_ADDRESS = gql`
  mutation EditAddress($data: AddressInput, $addressId: ID!) {
    EditAddress(data: $data, addressId: $addressId) {
      code
      success
      message
    }
  }
`;

export const DELETE_ADDRESS = gql`
  mutation DeleteAddress($addressId: ID!) {
    DeleteAddress(addressId: $addressId) {
      code
      success
      message
    }
  }
`;

export const FETCH_ADDRESS_FROM_GOOGLE = gql`
  query GetPlaceFromMapApi($searchValue: String, $country: String) {
    GetPlaceFromMapApi(searchValue: $searchValue, country: $country) {
      code
      success
      message
      places {
        placeName
        placeId
        reference
      }
    }
  }
`;

export const FETCH_DETAILS_GEO_CODE = gql`
  query GetGeocodeData($placeId: String) {
    GetGeocodeData(placeId: $placeId) {
      code
      success
      message
      city
      postalCode
      address
      state
      code
    }
  }
`;
