import React, { useState, useEffect } from 'react';
import { UserOutlined, MailOutlined } from '@ant-design/icons';
import { Input, Button, Modal, Typography, message } from 'antd';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import Cookies from 'js-cookie';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import Logo from './logo.js';

const { Title, Paragraph, Link } = Typography;

const SUBSCRIBE_USER = gql`
   mutation mcSubscriber($email: String!, $firstName: String!, $phoneNumber: String!) {
    mcSubscriber(email: $email, firstName: $firstName, phoneNumber: $phoneNumber) {
      success
      message
    }
  }
`;

const MailchimpPopup = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [name, setName] = useState('');
  const [errorMessage, setErrorMessage] = useState({name: '', email: '', phone: ''});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const modalStatus = Cookies.get('modalStatus');
  
  const [subscribeUser] = useMutation(SUBSCRIBE_USER);

  useEffect(() => {
    if (!modalStatus) {
      const timer = setTimeout(() => {
        setIsModalOpen(true);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [modalStatus]);
  
  const styles = {
    container: {
      backgroundColor: '#feeeec',
    },
    typographyContainer: {
      textAlign: 'center',
    },
    modalInput: {
      marginTop: '16px',
    },
    inputRadius: {
      borderRadius: '5px',
    },
    submitButton: {
      width: '100%',
      height: '38px',
      backgroundColor: 'black',
      borderColor: 'black',
      color: 'white',
      marginTop: '16px',
    },
    phoneInput: {
      width: '100%',
      height: '38px',
      border: '1px solid #d6d6d6',
      borderRadius: '5px',
      fontSize: '16px',
      transition: 'box-shadow 0.1s ease',
    },
    phoneInputFocused: {
      boxShadow: '0 0 1px 1px rgba(0, 123, 255, 0.2)',
      borderColor: '#007bff',
    }
    ,
    errorStyle: {
      color: {
        color: 'red',
      },
      border: {
        border: '1px solid red',
      }
    }
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    if(!modalStatus){
      Cookies.set('modalStatus', 'closed', { expires: 7 });
    }
  };

  const validateFields = () => {
    let valid = true;
    const newErrorMessage = { name: '', email: '', phone: '' };

    if (!name) {
      newErrorMessage.name = 'First name field is required.';
      valid = false;
    }
    if (!email) {
      newErrorMessage.email = 'E-mail field is required.';
      valid = false;
    } else if (!email.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)) {
      newErrorMessage.email = 'E-mail field is invalid.';
      valid = false;
    }
    if (!phone) {
      newErrorMessage.phone = 'Phone number field is required.';
      valid = false;
    } else if (!phone.match(/^[1-9]\d{0,14}$/)) {
      newErrorMessage.phone = 'Phone number field is invalid.';
      valid = false;
    }

    setErrorMessage(newErrorMessage);
    return valid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateFields()) {
      return; 
    }
      setIsSubmitting(true);
  
      try {
        const { data } = await subscribeUser({
          variables: {
            email,
            firstName: name,
            phoneNumber: phone,
          },
        });
    
        if (data.mcSubscriber.success) {
          messageApi.open({
            type: 'success',
            content: data.mcSubscriber.message,
            style: {
              position: 'relative',
              top: '10vh'
            },
          });
        }  else {
          messageApi.open({
            type: 'warning',
            content: data.mcSubscriber.message,
            style: {
              position: 'relative',
              top: '10vh'
            },
          });
        }
      } catch (error) {
        messageApi.open({
          type: 'error',
          content: 'Something went wrong, please try again.',
          style: {
            position: 'relative',
            top: '100px'
          },
        });
      } finally {
        setIsSubmitting(false);
      }
  
  };

  return (
    <>
      <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null}>
        <Typography style={styles.typographyContainer}>
          <Logo />
          <Title level={3}>NEVER MISS A NEW OR A DEAL</Title>
          <Paragraph>Subscribe for 15% OFF for your first order</Paragraph>
        </Typography>
        <div style={styles.modalInput}>
          <Input style={{...styles.inputRadius,...(errorMessage.name ? styles.errorStyle.border : {})}} size="large" placeholder="First name" prefix={<UserOutlined />} value={name} onChange={(e) => setName(e.target.value)} />
          {errorMessage.name  &&  <div style={styles.errorStyle.color}>{errorMessage.name}</div>}
        </div>
        <div style={styles.modalInput}>
          <Input style={{...styles.inputRadius,...(errorMessage.email ? styles.errorStyle.border : {})}} size="large" placeholder="E-mail address" prefix={<MailOutlined />} value={email} onChange={(e) => setEmail(e.target.value)} />
          {errorMessage.email && <div style={styles.errorStyle.color}>{errorMessage.email}</div>}
        </div>
        <div style={styles.modalInput}>
          <PhoneInput country={'us'} value={phone} onChange={(phone) => setPhone(phone)}
            inputStyle={{
              ...styles.phoneInput,
              ...(isFocused ? styles.phoneInputFocused : {}),
              ...(errorMessage.phone ? styles.errorStyle.border : {}),
            }}
            placeholder="Phone number"
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            containerStyle={{ width: '100%' }}
          />
          {errorMessage.phone && <div style={styles.errorStyle.color}>{errorMessage.phone}</div>}
        </div>
        <div>
          {contextHolder}
          <Button type="primary" style={styles.submitButton} onClick={handleSubmit} loading={isSubmitting}>
            Subscribe
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default MailchimpPopup;
