import React from "react";

export const AfterPaylogo = ({ width = "100" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height="21.984"
      viewBox="0 0 114.372 21.984"
      style={{ fill: "#3d3d3d" }}
    >
      <g id="Afterpay_Logo_Black" transform="translate(-130.632 -111.915)">
        <path
          id="Path_87"
          data-name="Path 87"
          d="M479.711,116.352l-3.444-1.971-3.494-2a3.47,3.47,0,0,0-5.2,3.006v.448a.674.674,0,0,0,.348.6l1.623.926a.672.672,0,0,0,1.005-.587V115.7a.686.686,0,0,1,1.025-.6l3.186,1.832,3.176,1.822a.683.683,0,0,1,0,1.185l-3.176,1.822-3.186,1.832a.686.686,0,0,1-1.025-.6v-.528a3.467,3.467,0,0,0-5.2-3.006l-3.494,2-3.444,1.971a3.476,3.476,0,0,0,0,6.023l3.444,1.971,3.494,2a3.47,3.47,0,0,0,5.2-3.006v-.448a.674.674,0,0,0-.348-.6l-1.623-.926a.672.672,0,0,0-1.005.587v1.065a.686.686,0,0,1-1.025.6l-3.186-1.832-3.176-1.822a.683.683,0,0,1,0-1.185l3.176-1.822,3.186-1.832a.686.686,0,0,1,1.025.6v.528a3.467,3.467,0,0,0,5.2,3.006l3.494-2,3.444-1.971A3.476,3.476,0,0,0,479.711,116.352Z"
          transform="translate(-236.447 0)"
          fill="#3d3d3d"
        />
        <g
          id="Group_210"
          data-name="Group 210"
          transform="translate(207.526 117.009)"
        >
          <path
            id="Path_88"
            data-name="Path 88"
            d="M423.584,130.451l-8.064,16.655h-3.345l3.016-6.222-4.749-10.433h3.435l3.046,6.989,3.325-6.989Z"
            transform="translate(-410.444 -130.451)"
            fill="#3d3d3d"
          />
        </g>
        <g
          id="Group_212"
          data-name="Group 212"
          transform="translate(130.632 112.17)"
        >
          <g id="Group_211" data-name="Group 211" transform="translate(0 0)">
            <path
              id="Path_89"
              data-name="Path 89"
              d="M140.04,135.812a3.22,3.22,0,1,0-3.215,3.385,3.231,3.231,0,0,0,3.215-3.385m.03,5.913v-1.533a4.735,4.735,0,0,1-3.733,1.722,5.773,5.773,0,0,1-5.7-6.1,5.881,5.881,0,0,1,5.774-6.122,4.729,4.729,0,0,1,3.663,1.7V129.9h2.907v11.827Z"
              transform="translate(-130.632 -125.061)"
              fill="#3d3d3d"
            />
            <path
              id="Path_90"
              data-name="Path 90"
              d="M193.34,126.882c-1.015,0-1.3-.378-1.3-1.374v-5.246h1.872v-2.578h-1.872V114.8H189.06v2.887h-3.843v-1.175c0-1,.378-1.374,1.424-1.374h.657v-2.29h-1.434c-2.459,0-3.624.806-3.624,3.265v1.583h-1.653v2.568h1.653v9.248h2.977v-9.248h3.843v5.794c0,2.409.926,3.454,3.335,3.454h1.533v-2.628Z"
              transform="translate(-166.86 -112.845)"
              fill="#3d3d3d"
            />
            <path
              id="Path_91"
              data-name="Path 91"
              d="M242.047,134.721a2.834,2.834,0,0,0-2.937-2.459,2.917,2.917,0,0,0-2.977,2.459Zm-5.943,1.842a2.943,2.943,0,0,0,3.056,2.748,3.123,3.123,0,0,0,2.787-1.533H245a6.176,6.176,0,0,1-12-1.971,6.118,6.118,0,1,1,12.235,0,3.752,3.752,0,0,1-.07.757Z"
              transform="translate(-204.874 -125.035)"
              fill="#3d3d3d"
            />
            <path
              id="Path_92"
              data-name="Path 92"
              d="M321.783,135.786a3.22,3.22,0,1,0-3.215,3.385,3.279,3.279,0,0,0,3.215-3.385m-9.368,10.742V129.873h2.907v1.533a4.7,4.7,0,0,1,3.733-1.752,5.821,5.821,0,0,1,5.7,6.1,5.881,5.881,0,0,1-5.774,6.122,4.574,4.574,0,0,1-3.574-1.583v6.222h-3Z"
              transform="translate(-262.46 -125.035)"
              fill="#3d3d3d"
            />
            <path
              id="Path_93"
              data-name="Path 93"
              d="M370.656,135.812a3.22,3.22,0,1,0-3.215,3.385,3.231,3.231,0,0,0,3.215-3.385m.03,5.913v-1.533a4.735,4.735,0,0,1-3.733,1.722,5.773,5.773,0,0,1-5.7-6.1,5.881,5.881,0,0,1,5.774-6.122,4.729,4.729,0,0,1,3.663,1.7V129.9h2.907v11.827Z"
              transform="translate(-297.874 -125.061)"
              fill="#3d3d3d"
            />
            <path
              id="Path_94"
              data-name="Path 94"
              d="M285.627,131.028a2.925,2.925,0,0,1,2.558-1.374,2.9,2.9,0,0,1,1.274.269v3.016a3.736,3.736,0,0,0-2.1-.538,2.083,2.083,0,0,0-1.643,2.29v7H282.71V129.873h2.907v1.155Z"
              transform="translate(-240.918 -125.035)"
              fill="#3d3d3d"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const InfoIco = ({width= "100"}) => {
  return (
    <svg
      width={width}
      height="20"
      version="1.1"
      viewBox="0 0 1200 1200"
      xmlns="http://www.w3.org/2000/svg"
      fill="#757575"
    >
      <path d="m600 48.719c-146.21 0-286.43 58.082-389.81 161.47-103.39 103.38-161.47 243.61-161.47 389.81s58.082 286.43 161.47 389.81c103.38 103.39 243.61 161.47 389.81 161.47s286.43-58.082 389.81-161.47c103.39-103.38 161.47-243.61 161.47-389.81-0.19141-146.15-58.336-286.26-161.68-389.6s-243.45-161.49-389.6-161.68zm0 1006.6v0.003906c-120.75 0-236.55-47.969-321.93-133.35-85.383-85.379-133.35-201.18-133.35-321.93s47.969-236.55 133.35-321.93c85.379-85.383 201.18-133.35 321.93-133.35s236.55 47.969 321.93 133.35c85.383 85.379 133.35 201.18 133.35 321.93-0.19141 120.69-48.219 236.38-133.56 321.72s-201.04 133.37-321.72 133.56zm0-601.56c-12.73 0-24.938 5.0586-33.941 14.059-9 9.0039-14.059 21.211-14.059 33.941v363.24c0 17.148 9.1484 32.992 24 41.566 14.852 8.5781 33.148 8.5781 48 0 14.852-8.5742 24-24.418 24-41.566v-363.24c0-12.73-5.0586-24.938-14.059-33.941-9.0039-9-21.211-14.059-33.941-14.059zm0-166.68c-12.73 0-24.938 5.0586-33.941 14.059-9 9.0039-14.059 21.211-14.059 33.941v6.1211c0 17.148 9.1484 32.996 24 41.57s33.148 8.5742 48 0 24-24.422 24-41.57v-6.1211c0-12.73-5.0586-24.938-14.059-33.941-9.0039-9-21.211-14.059-33.941-14.059z" />
    </svg>
  );
};
