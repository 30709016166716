import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { useDispatch } from "react-redux";
import {
  CREATE_ACCOUNT,
  FORGOT_PASSWORD,
  loginUser,
  onRediret,
} from "../../store/modules";
import Button from "../Shared/Button";
import { USER_LOGIN } from "../../graphql/modules";
import { successNotify, warnNotify } from "../../util";
import { Link } from "react-router-dom";

const Login = () => {
  const dispatch = useDispatch();
  const handleRedirect = (set) => {
    dispatch(
      onRediret({ type: set === "forgot" ? FORGOT_PASSWORD : CREATE_ACCOUNT })
    );
  };

  // input state initialize
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  // login api
  const [handleLogin, {loading}] = useMutation(USER_LOGIN);
  const handleSubmit = async()=>{
    try {
      const { data:{Login}} = await handleLogin({
        variables:{
          email: email.toLowerCase(),
          password: password
        }
      })
      if(Login.success){
        successNotify(Login.message)
        dispatch(loginUser(Login))
      } else {
        warnNotify(Login.message)
      }
    } catch (error) {
    }
  }


  // login button disable
  const disable = !email || !password;

  return (
    <div className="login-form-container">
      <div className="user-inner-drawer mb-5">
        <div className="login-title">
          <h5>Your Account</h5>
        </div>
        <form>
          <div className="mb-3">
            <label htmlFor="exampleInputEmail1" className="form-label" style={{color:"#3d3d3d"}}>
              Email*
            </label>
            <input
              type="email"
              className="form-control cmn-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              onChange={e=>setEmail(e.target.value)}
              value={email.toLowerCase()}
              style={{color:"#3d3d3d"}}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="exampleInputPassword1" className="form-label" style={{color:"#3d3d3d"}}>
              Password*
            </label>
            <input
              type="password"
              className="form-control cmn-control"
              id="exampleInputPassword1"
              onChange={e=>setPassword(e.target.value)}
              style={{color:"#3d3d3d"}}
            />
          </div>
          <div className="row g-3">
            <div className="col">
              <div className="form-group form-check d-flex">
                <input
                  type="checkbox"
                  className="form-check-input check_mod"
                  id="exampleCheck1"
                  style={{marginRight:'5px', color:"#3d3d3d"}}
                />
                <label className="form-check-label" htmlFor="exampleCheck1" style={{color:"#3d3d3d"}}>
                  Remember Me
                </label>
              </div>
            </div>
            <div className="col forget_p text-end">
              <button
                style={{
                  border: "none",
                  background: "transparent",
                  textDecoration: "underline",
                  color: "#3d3d3d",
                  fontSize:"16px",
                  padding:'0'
                }}
                onClick={() => handleRedirect("forgot")}
              >
                Forget Password?
              </button>
            </div>
          </div>
          <Button btnText="Login" loading={loading} disabled={disable} onClick={handleSubmit}/>
        </form>
        <hr />
        <div className="no-account">
          <div className="no-acc-title">
            <h5>Don't have an account?</h5>
            <span>Create an account with us for future convenience!</span>
          </div>
          <div className="conv-list">
            <ul>
              <li>
                <span>
                  <i className="fas fa-heart"> </i>
                </span>{" "}
                &nbsp; Create a Wish List
              </li>
              <li>
                <span>
                  <i className="fas fa-shopping-bag"></i>
                </span>{" "}
                &nbsp; Receive Newsletters from ChillFit
              </li>
              <li>
                <span>
                  <i className="fas fa-cog"></i>
                </span>{" "}
                &nbsp; Manage Account Information
              </li>
              <li>
                <span>
                  <i className="fas fa-undo"></i>
                </span>{" "}
                &nbsp; Order Details & Returns
              </li>
            </ul>
          </div>
          <div className="read-privacy">
            <span>
              Please read ChillFit <Link to="/faq">Privacy Policy</Link> before sign
              up.
            </span>
          </div>
          <Button
            btnText="Create Now"
            btnClass="create-now-btn"
            style={{marginTop:'18px'}}
            onClick={() => handleRedirect("create")}
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
