import gql from "graphql-tag";
import { CORE_ELEMENT_PRODUCT } from "./fragment";

export const FETCH_ONLY_CATEGORY = gql`
  query FetchCategory {
    FetchCategory {
      code
      success
      message
      result {
        _id
        name
        sizeType
        isColored
        coverImage
        isImagedCategory
        products {
          _id
        }
        subcategory {
          _id
          name
          sizeType
          coverImage
          products {
            _id
          }
          subcategoryGroup {
            _id
            name
          }
        }
      }
    }
  }
`;

export const FETCH_SUBCATEGORY = gql`
  query FetchSingleSubcategory(
    $subcategoryId: ID
    $limit: Int
    $offset: Int
    $filters: SingleSubcategoryFilterInput
  ) {
    FetchSingleSubcategory(
      subcategoryId: $subcategoryId
      limit: $limit
      offset: $offset
      filters: $filters
    ) {
      code
      success
      message
      result {
        _id
        name
        category {
          _id
          name
        }
        products {
          _id
          name
          description
          isAccessories
          price
          defaultImg
          topSizing {
            _id
            quantity
            size {
              _id
              name
            }
          }
          bottomSizing {
            _id
            quantity
            size {
              _id
              name
            }
          }
          gallery
        }
      }
      productCount
    }
  }
`;

export const FETCH_ALL_SIZE = gql`
  query FetchSizing($type: SizeType) {
    FetchSizing(type: $type) {
      code
      message
      success
      result {
        _id
        name
      }
    }
  }
`;

export const FETCH_STOCK_LIMIT = gql`
  query FetchStockLimit {
    FetchStockLimit {
      code
      success
      limit
    }
  }
`;



export const FETCH_CAT_SUB_NAME = gql`
  query FetchCategoryOrSubcategoryName($subcategoryId: ID, $categoryId: ID) {
    FetchCategoryOrSubcategoryName(
      subcategoryId: $subcategoryId
      categoryId: $categoryId
    ) {
      name
      sizeType
    }
  }
`;






