import React, { useState } from "react";
import ReactDOM from "react-dom";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  AfterpayClearpayMessageElement,
  // PaymentRequestButtonElement,
} from "@stripe/react-stripe-js";
import { Link } from "react-router-dom";
import { warnNotify } from "../../util";
// import StripePaymentRequestButton from "./StripePaymentButtom";
import { useMutation } from "@apollo/react-hooks";
import {
  GET_AFTERPAY_TOKEN,
  GET_PAYPAL_TOKEN,
} from "../../graphql/modules/order";
import { Collapse, Radio, Tabs, Modal } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SHIPPING, redirectToCheckOut } from "../../store";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
// import { useLocation } from "react-router-dom/cjs/react-router-dom";
import productType from "../../constants/productType";

const PayPalButton = window.paypal?.Buttons.driver("react", {
  React,
  ReactDOM,
});

const { Panel } = Collapse;

export const PaymentOption = ({
  paymentDetails,
  setPaymentDetails,
  placeOrder,
  billingDisable,
  loading,
  type,
  orderNote,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const history = useHistory();

  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const guestEmail = useSelector((state) => state.guest.email);
  const shippingAddress = JSON.parse(localStorage.getItem("shipping"));
  const billingAddress = JSON.parse(localStorage.getItem("billing"));
  const [payMethod, setPayMethod] = useState("card");
  const [isApplePayAvailable, setIsApplePayAvailable] = useState(false);

  const { items, preItems } = useSelector((state) => state.cart);

  // inital state
  const [method, setMethod] = useState("stripe");
  const [err, setErr] = useState({ fullname: "" });
  const [payInfoData, setPayInfoData] = useState({
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
  });

  const dispatch = useDispatch();

  const onHandleCardElement = (data) => {
    const { complete, elementType } = data;
    setPayInfoData({ ...payInfoData, [elementType]: complete });
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setPaymentDetails({ ...paymentDetails, [name]: value });
    if (name === "firstname") {
      if (value === "") {
        setErr({ ...err, fullname: "This fiels is required!" });
      } else {
        setErr({ ...err, fullname: "" });
      }
    }
    if (name === "lastname") {
      if (value === "") {
        setErr({ ...err, lastname: "This fiels is required!" });
      } else {
        setErr({ ...err, lastname: "" });
      }
    }
  };

  const [stripeLoading, setStripeLoading] = useState(false);
  const handleSubmit = async (e) => {
    setStripeLoading(true);
    // e.preventDefault()
    const cardElement = elements.getElement(CardNumberElement);
    const { token, error } = await stripe.createToken(cardElement);
    if (token) {
      placeOrder(token.id, "stripe");
      // setStripeToken(token.id);
    } else {
      warnNotify(error?.message || "Something went wrong!");
    }
    setStripeLoading(false);
  };

  // handle check box
  const [check, setCheck] = useState(false);
  // const [stripeToken, setStripeToken] = useState("");
  const handleCheckBox = (e) => {
    setCheck(e.target.checked);
  };

  const disable =
    !payInfoData.cardNumber ||
    !payInfoData.cardExpiry ||
    !payInfoData.cardCvc ||
    !check;

  const [purchaseAmount, setPurchaseAmount] = useState(0.0);
  const GetPurchaseAmount = async () => {
    const res = await JSON.parse(localStorage.getItem("total-price"));
    setPurchaseAmount(Number(parseFloat(res).toFixed(2)));
  };

  useEffect(() => {
    GetPurchaseAmount();
    setIsApplePayAvailable(window.ApplePaySession?.canMakePayments());
  }, []);

  const [GetPaypalToken] = useMutation(GET_PAYPAL_TOKEN);
  const createOrder = async (data, actions) => {
    try {
      const {
        data: { InitializePaypalPayment },
      } = await GetPaypalToken({
        variables: {
          purchaseAmount: purchaseAmount,
        },
      });
      if (InitializePaypalPayment?.success) {
        return InitializePaypalPayment?._id;
      }
    } catch (error) {
      console.log(error.message);
    }
    // return actions.order.create({
    //   purchase_units: [
    //     {
    //       amount: {
    //         value: parseFloat(purchaseAmount),
    //       },
    //     },
    //   ],
    // });
  };
  const onApprove = async (data, actions) => {
    const id = data?.orderID;
    await placeOrder(id, "paypal");
  };

  const [afterpayLoading, setAfterpayLoading] = useState(false);
  const [GetAfterpayToken] = useMutation(GET_AFTERPAY_TOKEN);
  const handleAfterPay = async () => {
    setAfterpayLoading(true);
    // localStorage.setItem('order-note', orderNote);
    const productItem = [
      ...items,
      ...preItems.map((pr) => ({ ...pr, isPreOrderItem: true })),
    ];
    let orderItems = productItem.map(({ colorDetails, part, ...item }) => {
      const colors =
        part === productType.fullSet
          ? {
              topColor: colorDetails?.topColor,
              topColorName: colorDetails?.topColorName,
              bottomColorName: colorDetails?.bottomColorName,
              bottomColor: colorDetails?.bottomColor,
              colorId: colorDetails?._id,
            }
          : {
              [part?.toLowerCase() + "Color"]:
                colorDetails[part?.toLowerCase() + "Color"],
              [part?.toLowerCase() + "ColorName"]:
                colorDetails[part?.toLowerCase() + "ColorName"],
              colorId: colorDetails?._id,
            };
      return {
        product: item._id,
        bottomSizing: item.bottomName,
        topSizing: item.topName,
        quantity: item.quantity,
        isPreOrderItem: item?.isPreOrderItem,
        style: item?.style,
        isTopPre: item?.isTopPre,
        isBottomPre: item?.isBottomPre,
        ...colors,
      };
    });
    try {
      const {
        data: { InitializeAfterPayPayment },
      } = await GetAfterpayToken({
        variables: {
          purchaseAmount,
          orderItems: orderItems,
          isPreOrder: type === "regular" ? false : true,
        },
      });
      if (InitializeAfterPayPayment.success) {
        onRequestPaymentAfterpay(InitializeAfterPayPayment._id);
      } else {
        if (InitializeAfterPayPayment.code === "STOCK_OUT") {
          dispatch(redirectToCheckOut({ type: SHIPPING }));
          history.push("/stock-out");
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const onRequestPaymentAfterpay = async (secretKey) => {
    localStorage.setItem("order-note", orderNote);
    const methodDetails = {
      payment_method: {
        billing_details: {
          address: {
            city: billingAddress?.city,
            line1: billingAddress?.address,
            postal_code: billingAddress?.zipCode,
            state: billingAddress?.state,
            country: billingAddress?.country,
          },
          phone: billingAddress?.phone,
          name: billingAddress?.firstname + " " + billingAddress?.lastname,
          email: isAuthenticated ? user?.email : guestEmail,
        },
      },
      shipping: {
        name: shippingAddress?.firstname + " " + shippingAddress?.lastname,
        phone: shippingAddress?.phone,
        address: {
          city: shippingAddress?.city,
          line1: shippingAddress?.address,
          postal_code: shippingAddress?.zipCode,
          state: shippingAddress?.state,
          country: shippingAddress?.country,
        },
      },
      return_url: `${window.location.origin}/order-proccessing?type=${type}&method=${payMethod}&return=true`,
    };
    if (payMethod === "afterpay")
      stripe.confirmAfterpayClearpayPayment(secretKey, { ...methodDetails });
    else {
      const res = await stripe.confirmKlarnaPayment(secretKey, {
        // ...methodDetails,
        payment_method: {
          billing_details: {
            address: {
              country: billingAddress?.country,
            },
            email: isAuthenticated ? user?.email : guestEmail,
          },
        },
        return_url: `${window.location.origin}/order-proccessing?type=${type}&method=${payMethod}&return=true`,
      });
      if (res?.error) {
        Modal.warning({
          content: res?.error?.message?.replace(
            "`billing_details[address][country]`",
            "billing country"
          ),
          okButtonProps: {
            style: { background: "#2d2d2d", color: "#ffffff", border: "none" },
          },
        });
        setAfterpayLoading(false);
      }
    }
  };

  const handleApplePay = async () => {
    //  console.log(window.ApplePaySession)
    if (!stripe || !isApplePayAvailable) {
      console.log("Stripe or Apple Pay is not available.");
      return;
    }

    const paymentRequest = {
      country: "US",
      currency: "usd",
      total: {
        label: "Your Order Total",
        amount: 10000,
      },
    };

    const { error } = await stripe.paymentRequestWithApplePay(paymentRequest);

    if (error) {
      console.error("Error processing Apple Pay payment:", error);
    } else {
      console.log("Apple Pay payment successful!");
    }
  };

  // const handlePaymentMethodReceived = async (event, counter) => {
  //   await event.complete("success");
  //   console.log("Counter", counter);
  // };

  // const onChangeMethod = (e) => {
  //   setMethod(e.target.value);
  // };

  return (
    <div className="payment_option">
      <div className="pyment_title d-flex justify-content-between">
        <h5
          style={{ fontSize: "14px", fontWeight: "700" }}
          className="text-uppercase"
        >
          <span>
            <img src="/images/atm-card.png" alt="logo" width="20" />
          </span>
          &nbsp; Payment Methods
        </h5>
      </div>
      <hr style={{ marginBottom: "5px" }} />
      <p style={{ color: "rgb(117, 117, 117)", marginBottom: "10px" }}>
        Please select your payment methods.
      </p>
      <Collapse
        bordered={false}
        className="pay-collapse"
        accordion
        activeKey={method}
        defaultActiveKey={["stripe"]}
      >
        <Panel
          showArrow={false}
          header={
            <Radio
              onChange={() => setMethod("stripe")}
              checked={method === "stripe"}
              value="stripe"
              style={{
                fontSize: "14px",
                fontWeight: "600",
                color: "#3d3d3d",
              }}
            >
              {/* Credit / Debit Card */}
              Stripe
            </Radio>
          }
          key="stripe"
        >
          <Tabs
            type="card"
            className="payment-method-tab mt-2"
            defaultActiveKey={["card"]}
            onChange={(key) => setPayMethod(key)}
          >
            <Tabs.TabPane
              tab={
                <div style={{ fontWeight: "bold" }}>
                  <span className="me-2">
                    <i className="far fa-credit-card"></i>
                  </span>
                  Card
                </div>
              }
              key="card"
            >
              <div className="row">
                <div className="col-12">
                  <label htmlFor="cardholder" className="form-label">
                    Card holder name*
                  </label>
                  <input
                    type="text"
                    name="fullname"
                    value={paymentDetails.fullname}
                    onChange={handleInput}
                    className="form-control cmn_ctrl"
                    id="cardholder"
                  />
                  {err.fullname && (
                    <div className="invalid-feedback">
                      <i className="fas fa-exclamation-circle"></i>
                      &nbsp;{err.fullname}
                    </div>
                  )}
                </div>
                <div className="mb-3 mt-3">
                  <label className="form-label">Credit card number*</label>
                  <div
                    style={{
                      border: "1px solid #ced4da",
                      padding: "10px 10px",
                    }}
                    className="form-control ccc cmn_ctrl mb-3"
                  >
                    <CardNumberElement onChange={onHandleCardElement} />
                  </div>
                  <div className="d-flex justify-content-between">
                    <div style={{ width: "48%" }}>
                      <label className="form-label">Expiration Date*</label>
                      <div
                        style={{
                          border: "1px solid #ced4da",
                          padding: "10px 10px",
                        }}
                      >
                        <CardExpiryElement onChange={onHandleCardElement} />
                      </div>
                    </div>

                    <div
                      style={{
                        width: "48%",
                      }}
                    >
                      <label className="form-label">Security Code*</label>
                      <div
                        style={{
                          border: "1px solid #ced4da",
                          padding: "10px 10px",
                        }}
                      >
                        <CardCvcElement onChange={onHandleCardElement} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6"></div>
                <div className="varified_by mb-3">
                  <p>
                    Your payment is verified and secured by{" "}
                    <a
                      href="https://stripe.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Stripe
                    </a>
                    .
                  </p>
                </div>

                <div className="terms_condition_check">
                  <div className="row">
                    <div className="col-auto">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="termcheck"
                          onChange={handleCheckBox}
                        />
                        <label
                          htmlFor="termcheck"
                          className="form-check-label"
                          style={{ color: "#3d3d3d" }}
                        >
                          I have fully read and accept the{" "}
                          <Link to="/faq">Terms of condition</Link> and{" "}
                          <Link to="/faq">Privacy Policy</Link>.
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="">
                  <div className="">
                    <button
                      onClick={handleSubmit}
                      className="btn checkout_btn flt_right"
                      disabled={
                        disable || billingDisable || loading || stripeLoading
                      }
                    >
                      {loading || stripeLoading ? (
                        <i
                          className="fas fa-spinner fa-spin me-2"
                          style={{ color: "#fff" }}
                        ></i>
                      ) : (
                        ""
                      )}{" "}
                      Place your order
                    </button>
                  </div>
                </div>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={
                <div style={{ fontWeight: "bold" }}>
                  <span>
                    <img
                      src="/images/afterpay.png"
                      className="me-1 mb-1"
                      width="24"
                      alt=""
                    />
                  </span>
                  Afterpay
                </div>
              }
              key="afterpay"
            >
              <div className="mb-2">
                <AfterpayClearpayMessageElement
                  options={{ amount: purchaseAmount * 100, currency: "USD" }}
                />
                <div className="mt-2">
                  <p style={{ fontSize: "16px" }}>
                    <span className="me-2">
                      <i className="fas fa-directions"></i>
                    </span>
                    After your submission, you will be securely redirected to
                    complete the next steps.
                  </p>
                </div>
              </div>
              <button
                className="btn checkout_btn flt_right"
                onClick={handleAfterPay}
                disabled={afterpayLoading}
              >
                {afterpayLoading ? (
                  <i
                    className="fas fa-spinner fa-spin mr-2"
                    style={{ color: "white" }}
                  ></i>
                ) : (
                  "Pay"
                )}
              </button>
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={
                <div style={{ fontWeight: "bold" }}>
                  <span>
                    <img
                      src="/images/klarna.svg"
                      className="me-1 mb-1"
                      width="28"
                      alt=""
                    />
                  </span>
                  Klarna
                </div>
              }
              key="klarna"
            >
              <div className="mb-2">
                <div className="mt-2">
                  <p style={{ fontSize: "16px" }}>
                    <span className="me-2">
                      <i className="fas fa-directions"></i>
                    </span>
                    After your submission, you will be securely redirected to
                    complete the next steps.
                  </p>
                </div>
              </div>
              <button
                className="btn checkout_btn flt_right"
                onClick={handleAfterPay}
                disabled={afterpayLoading}
              >
                {afterpayLoading ? (
                  <i
                    className="fas fa-spinner fa-spin mr-2"
                    style={{ color: "white" }}
                  ></i>
                ) : (
                  "Pay"
                )}
              </button>
            </Tabs.TabPane>
          </Tabs>
        </Panel>
        <Panel
          showArrow={false}
          header={
            <Radio
              onChange={() => setMethod("paypal")}
              value="paypal"
              checked={method === "paypal"}
              style={{
                fontSize: "14px",
                fontWeight: "600",
                color: "#3d3d3d",
              }}
            >
              PayPal
            </Radio>
          }
          key="paypal"
        >
          <div className="row">
            <div className="col-12">
              <div className="paypal-btn-component">
                <PayPalButton
                  createOrder={(data, actions) => createOrder(data, actions)}
                  onApprove={(data, actions) => onApprove(data, actions)}
                />
              </div>
            </div>
          </div>
        </Panel>
        {/* <Panel
          showArrow={false}
          header={
            <Radio
              onChange={() => setMethod("apple")}
              value="apple"
              checked={method === "apple"}
              style={{
                fontSize: "14px",
                fontWeight: "600",
                color: "#3d3d3d",
              }}
            >
              Apple Pay
            </Radio>
          }
          key="apple"
        >
          <div className="row">
            <div className="col-12">
              <button
                className="apple-pay-button"
                style={appleBtnStyle}
                onClick={handleApplePay}
              >
                <img
                  src="images/apple-pay-logo.png"
                  alt="Apple Pay"
                  width="62"
                  height="26"
                />
              </button>
            </div>
          </div>
        </Panel> */}
      </Collapse>
    </div>
  );
};

const appleBtnStyle = {
  border: "none",
  backgroundColor: "#000",
  borderRadius: "5px",
  padding: "8px 45px",
  cursor: "pointer",
  float: "right",
};
