import React, { useState } from "react";

const CustomSkeleton = ({ skeletonHeightWidth, imgSrc }) => {
  const [loadImage, setLoadimage] = useState(false);

  // handle load image
  const handleLoadImage = (e) => {
    setLoadimage(true);
  };

  return (
    <React.Fragment>
      {!loadImage ? (
        <div
          className="ant-skeleton ant-skeleton-element ant-skeleton-active"
          style={{
            width: `${skeletonHeightWidth}px`,
            height: `${skeletonHeightWidth*1.4}px`,
          }}
        >
          <span
            style={{ width: `${skeletonHeightWidth}px` }}
            className="ant-skeleton-avatar ant-skeleton-avatar-square"
          ></span>
        </div>
      ) : (
        <img
          src={imgSrc}
          alt="product"
          width="100%"
          height="auto"
        />
      )}
      <img
        className="d-none"
        src={imgSrc}
        alt="product"
        onLoad={handleLoadImage}
      />
    </React.Fragment>
  );
};

export default CustomSkeleton;
