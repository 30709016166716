import { combineReducers } from "redux";
import {
  AuthReducer,
  CartRducer,
  checkOut,
  forRedirect,
  forRedirectMobile,
  GuestUserReducer,
  PurchaseCartReducer,
  saveMenuCategory,
  setOrderDetails,
  SetOrderTypeForGuest,
  updateDrawerVisibleState,
} from "./modules";

export default combineReducers({
  auth: AuthReducer,
  cart: CartRducer,
  redirect: forRedirect,
  redirectForMobile: forRedirectMobile,
  guest: GuestUserReducer,
  cartLogin: PurchaseCartReducer,
  checkoutPage: checkOut,
  orderType: SetOrderTypeForGuest,
  allDrawer: updateDrawerVisibleState,
  categories: saveMenuCategory,
  order: setOrderDetails
});
