export const USER_COOKIE = "chillfit-auth";
export const CART_ITEM = "cart-item";
export const GUEST_USER = "guest-user";

// auth data
export function getAuthData() {
  const AuthData = localStorage.getItem(USER_COOKIE);
  //  console.log(AuthData)
  if (typeof AuthData === "string") return JSON.parse(AuthData);
  else return {};
}

export function setAuthData(auth) {
  const strState = JSON.stringify(auth);
  localStorage.setItem(USER_COOKIE, strState);
}

// cart data
export function setCartItem(item) {
  const strState = JSON.stringify(item);
  localStorage.setItem(CART_ITEM, strState);
}

export function getCartItem() {
  const CartItem = localStorage.getItem(CART_ITEM);
  if (typeof CartItem === "string") return JSON.parse(CartItem);
  else return {count: 0, items: [], preItems: [], orderType:""};
}

// guest user
export function setGuestUser(item) {
  const strState = JSON.stringify(item);
  localStorage.setItem(GUEST_USER, strState);
}

export function getGuestUser() {
  const GuestUser = localStorage.getItem(GUEST_USER);
  //  console.log(AuthData)
  if (typeof GuestUser === "string") return JSON.parse(GuestUser);
  else return { email: "" };
}

export function getCustomCookies() {
  const CustomCoockies = localStorage.getItem('CUSTOM_COOCKIES');
  //  console.log(AuthData)
  if (typeof CustomCoockies === "string") return JSON.parse(CustomCoockies);
  else return { customCoockies: "" };
}
