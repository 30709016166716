import gql from "graphql-tag";

export const FETCH_SUBJECTS = gql`
  query FetchSubjectsForUser($limit: Int, $offset: Int) {
    FetchSubjectsForUser(limit: $limit, offset: $offset) {
      code
      success
      message
      result {
        _id
        name
        subcategory {
          _id
          name
        }
      }
    }
  }
`;

export const FETCH_CURRENT_USER = gql`
query FetchUserById($id:ID) {
    FetchUserById(id:$id) {
      code
      success
      message
      user {
        _id
        firstname
        lastname
        avater
        email
        role
        status
        tutor {
          _id
          title
          bio
          graduateInstituteName
          graduateSubject
          hourlyRate
          rateAverage
          rateCount
          isFlaxible
          tutorLevel
          availability{
            day
            time
          }
          subjectsForTutor {
            _id
            name
            category{
              _id
              name
            }
          }
          offers{
            _id
            title
            description
            image
            hourlyRate
            subject{
              _id
              name
            }
          }
        }
        student{
          _id
          schoolName
          subject
        }
      }
    }
  }
`;
