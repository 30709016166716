//Constant

// initial state
const initialState = [];

export const saveMenuCategory = (state = initialState, action) => {
  //   state = [...payload];
  const { payload = [], type } = action;
  switch (type) {
    case "SAVE": {
      state = [...payload];
      return state;
    }

    default:
      return state;
  }
};

export const saveCategory = (payload = []) => {
  return {
    type: "SAVE",
    payload,
  };
};
