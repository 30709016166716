import React, { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

export const DropdownMenu = ({ item, idx, setArray, setShowMode }) => {
  const [submenu, setSubmenu] = useState(item?.subcategoryGroups);
  // useEffect(() => {
  //   // setSubmenu(organizeSubmenu(item?.subcategoryGroups));
  //   // console.log(item?.subcategoryGroups)
  // }, [item]);


  let startIndex = idx;

  const getRandomColor = () => {
    startIndex++;
    let colorCodes = ["#4284F4", "#EA4336", "#FABD05", "#34A853", "#EA4336"];
    if (startIndex > colorCodes.length) {
      startIndex = startIndex - colorCodes.length;
    }
    return colorCodes[startIndex - 1];
  };

  const handleHover = () => {
    document.getElementById("custom-dropdown").style.display = "block";
    setArray([...submenu]);
    setShowMode(item.isImagedCategory)
  };
  const handleHoverOut = () => {
    document.getElementById("custom-dropdown").style.display = "none";
  };

  return (
    <React.Fragment>
      {item?.subcategoryGroups?.length <1 ?(
        <div
          key={item?._id}
          className="menu-name"
          onMouseEnter={handleHover}
          onMouseLeave={handleHoverOut}
          id="drop-menu"
        >
          <Link
            to={`/category/${item?._id}?type=categoryId`}
            className="menu-name"
            style={{ textTransform: "uppercase" }}
          >
            <span className="inner-class" style={{fontWeight: "600"}}>
              {item?.isColored
                ? [...item?.name].map((letter, idx) => (
                    <span key={idx} style={{ color: getRandomColor(idx), fontWeight: "600" }}>
                      {letter}
                    </span>
                  ))
                : item?.name}
            </span>
          </Link>
        </div>
      ) : (
        <div
          className="menu-name"
          id="drop-menu"
          style={{ cursor: "pointer", textTransform: "uppercase" }}
          onMouseEnter={handleHover}
          onMouseLeave={handleHoverOut}
        >
          <span className="inner-class" style={{fontWeight: "600"}}>
            {item?.isColored
              ? [...item?.name].map((letter, idx) => (
                  <span key={idx} style={{ color: getRandomColor(idx), fontWeight: "600"  }}>
                    {letter}
                  </span>
                ))
              : item?.name}
          </span>
        </div>
      )}
    </React.Fragment>
  );
};
