//Constant
export const MENU_DRAWER = "MENU_DRAWER";
export const SEARCH_DRAWER = "SEARCH_DRAWER";
export const SUBSCRIBE_DRAWER = "SUBSCRIBE_DRAWER";
export const USER_DRAWER = "USER_DRAWER";
export const SHOPPING_DRAWER = "SHOPPING_DRAWER";
export const DRAWER_BUTTON = "DRAWER_BUTTON"
export const ALL = "ALL";
export const DRAWER_OUTER = "DRAWER_OUTER"

// initial state
const initialState = {
  MENU_DRAWER: false,
  SEARCH_DRAWER: false,
  SUBSCRIBE_DRAWER: false,
  USER_DRAWER: false,
  SHOPPING_DRAWER: false,
};

export const updateDrawerVisibleState = (state=initialState, action) => {
  const { type, payload, key } = action;
  switch (key) {
    case DRAWER_BUTTON:
      return {...initialState, [type]: payload};
    case DRAWER_OUTER:
      return {...state, [type]: false}
    case ALL:
      return {...initialState}
    default:
      return {...state}
  }
};

export const onSetDrawer = (type, payload, key) => {
  return {
    type,
    payload,
    key
  };
};
