//Constant
export const LOGIN = "LOGIN";
export const SHOPPING = "SHOPPING";
export const CREATE_ACCOUNT = "CREATE_ACCOUNT";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";

// initial state
const initialState = LOGIN;

export const forRedirect = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return LOGIN;

    case CREATE_ACCOUNT:
      return CREATE_ACCOUNT;
    case FORGOT_PASSWORD:
      return FORGOT_PASSWORD;
    default: {
      return LOGIN;
    }
  }
};

export const forRedirectMobile = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return LOGIN;
    case SHOPPING:
      return SHOPPING;
    case CREATE_ACCOUNT:
      return CREATE_ACCOUNT;
    case FORGOT_PASSWORD:
      return FORGOT_PASSWORD;
    default: {
      return SHOPPING;
    }
  }
};

export const onRediretMobile = ({ type }) => {
  // console.log(user_type,"=======", profileData)
  return {
    type: type,
  };
};

export const onRediret = ({ type }) => {
  // console.log(user_type,"=======", profileData)
  return {
    type: type,
  };
};
