import React, { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";
import { ADD_NEW_SUBSCRIBER } from "../../../graphql/modules";
import { useMutation } from "@apollo/react-hooks";
import { successNotify, warnNotify } from "../../../util";

const DrawerSubscribe = ({
  // onSubscribe,
  // handleVerify,
  // handleEmailSet,
  // disable,
  // loading,
  // err,
  setSubscribeDrawerClassName,
  subscribDrawerClassName,
  openSubscribe,
  wrapRefSubscriber,
}) => {
  // const dispatch = useDispatch();
  // const openDrawer = () => {
  //   dispatch(
  //     onSetDrawer(SUBSCRIBE_DRAWER, !allDrawer[SUBSCRIBE_DRAWER], DRAWER_BUTTON)
  //   );
  // };

  // const allDrawer = useSelector((state) => state?.allDrawer);
  const [token, setToken] = useState(false);
  const [email, setEmail] = useState("");
  const [err, setErr] = useState("");

  const handleVerify = () => {
    setToken(!token);
  };

  // email state
  const handleEmailSet = (e) => {
    const emailValidator =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!emailValidator.test(String(e.target.value).toLowerCase())) {
      setErr("Please enter a valid email, e.g. name@domain.com");
    } else {
      setErr("");
    }
    if (!e.target.value) {
      setErr("");
    }
    setEmail(e.target.value);
  };

  const [AddNewSubscriber, { loading }] = useMutation(ADD_NEW_SUBSCRIBER);
  const submitSubscriber = async () => {
    const {
      data: { AddSubscriber },
    } = await AddNewSubscriber({
      variables: {
        email,
      },
    });
    if (AddSubscriber.success) {
      successNotify(AddSubscriber.message);
      setSubscribeDrawerClassName(false)
    } else {
      warnNotify(AddSubscriber.message);
    }
  };

  useEffect(() => {
    const mt =
      (document.querySelector("#lempor_rasel")?.offsetHeight || 0) +
      document.querySelector(".navbar-area").offsetHeight;
    wrapRefSubscriber.current.style.setProperty("top", mt + `px`, "important");
  }, [subscribDrawerClassName]);

  const disable = !email || !token || err;

  return (
    <div
      className={`user-drawer ${subscribDrawerClassName ? "overlay" : ""}`}
      id="user-drawer"
    >
      <div
        ref={wrapRefSubscriber}
        className={`user-drawer-container ${
          subscribDrawerClassName ? "right_zero" : ""
        }`}
      >
        <div className="login-form-container">
          <div className="user-inner-drawer mb-5">
            <div className="mt-3 mb-3">
              <i
                style={{ cursor: "pointer" }}
                className="fas fa-times"
                onClick={openSubscribe}
              ></i>
            </div>
            <h5 style={{ fontWeight: "700", fontSize: "14px", color: "#000" }}>
              LET'S KEEP IN TOUCH
            </h5>
            <form action="#">
              <div className="login-title" style={{ marginTop: "0" }}>
                <h5
                  style={{
                    fontWeight: "400",
                    fontSize: "14px",
                    color: "#3d3d3d",
                  }}
                >
                  EMAIL *
                </h5>
              </div>
              <input
                type="text"
                onChange={handleEmailSet}
                className="form-control "
                placeholder="Please Enter Your Email*"
                required
                style={{ padding: "10px 10px " }}
              />
              <div>
                <small className="text-danger">{err}</small>
              </div>
              <div className="security-check mt-3">
                <span
                  className="text-uppercase"
                  style={{ fontWeight: "400", fontSize: "14px" }}
                >
                  Security Check*
                </span>
                <div className="recaptcha-wrapper">
                  <div className="row">
                    <ReCAPTCHA
                      sitekey="6LcHHsYaAAAAAL7C-R_tvQ7mXNDKTPAoWDmfmFdO"
                      render="explicit"
                      onChange={handleVerify}
                    />
                  </div>
                </div>
              </div>
            </form>
            <div className="subscribe-btn text-center">
              <button
                disabled={disable || loading}
                className="btn subscribe-btn"
                onClick={submitSubscriber}
                style={{ width: "100%", fontSize: "14px", fontWeight: "700" }}
              >
                Subscribe
              </button>
            </div>
            <p
              className="text-uppercase"
              style={{
                fontSize: "14px",
                fontWeight: "300",
                color: "#3d3d3d",
              }}
            >
              By subscribing to our newsletter you agree to the{" "}
              <Link to="/faq">privacy policy and term of use</Link>.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DrawerSubscribe;
