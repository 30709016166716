import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { FETCH_ONLY_CATEGORY } from "../../graphql/modules";
import { useQuery } from "@apollo/react-hooks";
import { saveCategory } from "../../store";

const Container = ({ children }) => {
  const dispatch = useDispatch();

  const { data } = useQuery(FETCH_ONLY_CATEGORY, {
    fetchPolicy: "no-cache",
  });

  const categoryList = data?.FetchCategory?.result || [];

  useEffect(() =>{
    let newCategoryList = categoryList.filter(
      (category) => category?.products?.length || category?.subcategory?.length
    );
    const key = "subcategoryGroupId";
    newCategoryList = newCategoryList.map((category) => {
      category.subcategory.map((subcategory) => {
        subcategory.subcategoryGroupId = subcategory.subcategoryGroup._id;
        return subcategory;
      });
      category.subcategory = category.subcategory.reduce((rv, x) => {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
      category.subcategoryGroups = Object.keys(category.subcategory).map(
        (key) => {
          const subcategory = category.subcategory[key];
          const obj = { ...subcategory[0].subcategoryGroup, subcategory };
          return obj;
        }
      );
    return category;
    });
   dispatch(saveCategory(newCategoryList));
   const version = localStorage.getItem('version');
   if(version!==process.env.REACT_APP_VERSION){
    localStorage.clear();
    localStorage.setItem('version', process.env.REACT_APP_VERSION);
    window.location.reload();
   }
  }, [categoryList?.length])

  
  return <div>{children}</div>;
};

export default Container;
