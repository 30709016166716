import gql from "graphql-tag";

export const CORE_ELEMENT_PRODUCT = gql`
  fragment CoreProductElement on Product {
    _id
    name
    status
    isAvailable
    price
    bottomPrice
    isNewProduct
    topPrice
    sizeType
    descriptions {
      title
      description
    }
    topDescriptions {
      title
      description
    }
    bottomDescriptions {
      title
      description
    }
    styles {
      name
      url
      quantity
    }
    quantity
    isAccessories
    code
    haveDiscount
    discount
    discountType
    gallery
    colors {
      _id
      topColor
      topColorName
      bottomColor
      bottomColorName
      gallery
      topGallery
      bottomGallery
      topSizing {
        size {
          _id
          name
        }
        quantity
      }
      bottomSizing {
        size {
          _id
          name
        }
        quantity
      }
    }
    category {
      _id
      name
      sizeType
      isShowAllSize
      isShowAllSize
    }
    subcategory {
      _id
      name
      sizeType
      isShowAllSize
      isShowAllSize
    }
  }
`;
