export const sortOfSize = (array, place) => {
  array.sort(
    (a, b) =>
      ORDER[place].indexOf(a?.size?.name) - ORDER[place].indexOf(b?.size?.name)
  );
  return array;
};

const ORDER = {
  TOP: [
    "30AA",
    "30A",
    "30B",
    "30C",
    "30D",
    "30E",
    "32AA",
    "32A",
    "32B",
    "32C",
    "32D",
    "32E",
    "34AA",
    "34A",
    "34B",
    "34C",
    "34D",
    "34E",
    "36AA",
    "36A",
    "36B",
    "36C",
    "36D",
    "36E",
    "38A",
    "38B",
    "38C",
    "38D",
    "38E",
    "40A",
    "40B",
    "40C",
    "40D",
    "40E",
    "42A",
    "42B",
    "42C",
    "42D",
    "42E",
    "44A",
    "44B",
    "44C",
    "44D",
    "44E",
    "XS",
    "S",
    "M",
    "L",
    "XL",
    "A",
    "B",
    "C",
    "D",
    "ONE SIZE (S-L)",
  ],
  BOTTOM: [
    "XXS",
    "XS",
    "S",
    "M",
    "L",
    "XL",
    "XXL",
    "XXXL",
    "XXXXL",
    "MATCH THE TOP",
  ],
};
