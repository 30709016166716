import ApolloClient from "apollo-client";
import { createUploadLink } from "apollo-upload-client";
import { InMemoryCache } from "@apollo/client/cache";
import { onError } from "apollo-link-error";
// import { ApolloLink } from "apollo-link";
import { getAuthData } from "../util";
import { GRAPHQL_URL, GRAPHQL_URL_WS } from "../config";
import { getMainDefinition } from "@apollo/client/utilities";
import { WebSocketLink } from "@apollo/client/link/ws";
import { setContext } from "@apollo/client/link/context";
// import { useDispatch } from "react-redux";
// import { logoutUser } from "../store";
import { ApolloLink, split } from '@apollo/client';

const auth = getAuthData();
const token = auth.jwtToken ? `Bearer ${auth.jwtToken}` : "";
console.log(auth,'======>', token)

// const dispatch = useDispatch();
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if(Array.isArray(graphQLErrors) && graphQLErrors[0].extensions.code==='UNAUTHENTICATED'){
    window.localStorage.clear();
    window.localStorage.setItem("customCoockie", "ok");
    window.location.reload();
  }
  if (graphQLErrors) {

    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  }

  if (networkError) console.log(`[Network error]: ${networkError}`);
});


// if (auth.jwtToken) {
//   const { exp } = jwtDecode(auth.jwtToken);
//   const sessionOut = exp * 1000 - 60000;
//   if (Date.now() >= sessionOut) {
//     window.localStorage.clear();
//     window.location.reload();
//   } else {
//   }
// }

const uploadLink = createUploadLink({
  uri: GRAPHQL_URL,
  headers: {
    Authorization: token,
  },
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      Authorization: token,
    },
  };
});

const wsLink = new WebSocketLink({
  uri: GRAPHQL_URL_WS,
  options: {
    reconnect: true,
    connectionParams: {
      Authorization: token,
    },
  },
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);

    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  authLink.concat(uploadLink)
);

const apollo = new ApolloClient({
  link: ApolloLink.from([errorLink, splitLink]),
  cache: new InMemoryCache(),
  defaultHttpLink: false,
  connectToDevTools: true,
});

apollo.defaultOptions = {
  watchQuery: {
    fetchPolicy: "network-only",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "network-only",
    errorPolicy: "all",
  },
};

export default apollo;
