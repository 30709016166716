import React from 'react';
export const svgIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 245.09 66">
      <g id="ChillFit_Logo_white" transform="translate(134.502 -46.9)">
        <path
          id="Path_1"
          data-name="Path 1"
          d="M-81.3,94.969l5.74,3.052A31.5,31.5,0,0,1-102.351,112.9a33.432,33.432,0,0,1-32.143-33.954A33.622,33.622,0,0,1-102.351,46.9,31.118,31.118,0,0,1-75.565,61.779l-5.74,3.052a25.452,25.452,0,0,0-21.046-11.064c-14.541.763-26.021,13.353-24.873,27.85.765,13.353,11.48,24.416,24.873,24.8A25.811,25.811,0,0,0-81.3,94.969Z"
          transform="translate(0 0)"
          fill="#FDD9E5"
        />
        <path
          id="Path_2"
          data-name="Path 2"
          d="M-80.565,87.819v24.415H-87.07V87.819c0-9.537-4.209-14.115-11.48-14.115s-11.862,4.578-11.862,14.5v24.034H-117.3V47h6.888V72.178A15.467,15.467,0,0,1-97.4,66.456C-88.6,66.837-80.565,71.034-80.565,87.819Z"
          transform="translate(48.559 0.283)"
          fill="#FDD9E5"
        />
        <rect
          id="Rectangle_2"
          data-name="Rectangle 2"
          width="6.888"
          height="27.551"
          transform="translate(-23.617 79.609)"
          fill="#FDD9E5"
        />
        <path
          id="Path_3"
          data-name="Path 3"
          d="M-50.56,50.2v6.505H-93.035V50.2Zm-6.505,21.429v6.888H-86.912v25.255H-93.8V72.012Z"
          transform="translate(114.892 9.128)"
          fill="#FDD9E5"
        />
        <path
          id="Path_4"
          data-name="Path 4"
          d="M-81.1,52.027A3.615,3.615,0,0,1-77.273,48.2a3.615,3.615,0,0,1,3.827,3.827,3.615,3.615,0,0,1-3.827,3.827A3.615,3.615,0,0,1-81.1,52.027Z"
          transform="translate(150.744 3.675)"
          fill="#FDD9E5"
        />
        <rect
          id="Rectangle_3"
          data-name="Rectangle 3"
          width="6.888"
          height="44.771"
          transform="translate(70.026 68.129)"
          fill="#FDD9E5"
        />
        <path
          id="Path_5"
          data-name="Path 5"
          d="M-50.614,102.867A16.828,16.828,0,0,1-60.946,106.3c-5.74-.763-9.566-4.195-9.566-14.49V67.785H-77.4V60.921h6.888V51.007l6.888-1.907V60.921h9.184v6.864h-9.184V91.427c0,3.813.383,5.72,1.913,7.245a6.938,6.938,0,0,0,8.036-.763Z"
          transform="translate(161.202 6.219)"
          fill="#FDD9E5"
        />
        <path
          id="Path_6"
          data-name="Path 6"
          d="M-94.712,90.266H-101.6V52h6.888Z"
          transform="translate(92.894 14.216)"
          fill="#FDD9E5"
        />
        <path
          id="Path_7"
          data-name="Path 7"
          d="M-90.712,103.634H-97.6V47.2h6.888Z"
          transform="translate(104.187 0.848)"
          fill="#FDD9E5"
        />
        <path
          id="Path_8"
          data-name="Path 8"
          d="M-97.251,51.261l-.383-.383c-.383-.383-.383-.765-.765-1.148a5.282,5.282,0,0,0-1.531-1.148,4.161,4.161,0,0,0-1.913-.383,4.853,4.853,0,0,0-2.3.383,5.283,5.283,0,0,0-1.531,1.148c-.383.765-.765,1.148-1.148,1.913a4.854,4.854,0,0,0-.383,2.3,6.8,6.8,0,0,0,1.531,3.827c1.148,1.148,2.3,2.679,3.444,3.827a21.029,21.029,0,0,0,3.444,2.679l1.531,1.148,1.531-1.148c1.148-.765,2.3-1.913,3.444-2.679a34.5,34.5,0,0,0,3.444-3.827A4.913,4.913,0,0,0-87.3,53.94a4.853,4.853,0,0,0-.383-2.3c-.383-.765-.383-1.531-1.148-1.913-.383-.383-1.148-1.148-1.531-1.148a4.853,4.853,0,0,0-2.3-.383,4.853,4.853,0,0,0-2.3.383c-.383.383-1.148.765-1.531,1.148s-.383.765-.765,1.148Z"
          transform="translate(77.078 3.675)"
          fill="#FDD9E5"
        />
      </g>
    </svg>
  );
  