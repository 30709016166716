import React from "react";
import { OrderItem } from "./OrderItem";
import { Tag } from "antd";
import { OrderStatus } from "../../config/constants";

export const OrderContainer = ({ orderDetails, typeTitle, status }) => {
  return (
    <div>
      <div className="bottom_head_order">
        <div className="d-flex justify-content-between">
          <p className="order_statuss" style={{ fontWeight: "bold" }}>
            Order Status: {getStatus(status)}
          </p>
          <div style={{ fontWeight: "bold" }}>{typeTitle}</div>
        </div>
        {orderDetails?.status !== OrderStatus.Pending &&
          orderDetails?.status !== OrderStatus.Canceled &&
          orderDetails?.status !== OrderStatus.PreOrder && (
            <span className="tracking_num" style={{ color: "#3d3d3d" }}>
              Track Shipment #{" "}
              <a
                style={{ color: "#3d3d3d", textDecoration: "underline" }}
                href={orderDetails?.trackingUrl || "/"}
                target="_blank"
                rel="noopener noreferrer"
              >
                <p className="d-inline ml-2">{orderDetails?.trackingNumber}</p>
              </a>
            </span>
          )}
      </div>
      <div className="row">
        {orderDetails?.items?.map((prod) => (
          <OrderItem
            key={prod?._id}
            prod={{
              ...prod,
              haveDiscount:
                prod?.haveDiscount &&
                ((prod?.discountType !== "preOrder" && !prod?.isPreOrderItem) ||
                  (prod?.discountType !== "instock" && prod?.isPreOrderItem)),
            }}
          />
        ))}
      </div>
    </div>
  );
};

const getStatus = (status) => {
  switch (status) {
    case OrderStatus.PreOrder:
      return (
        <Tag className="ml-2" color="orange">
          {status}
        </Tag>
      );
    case OrderStatus.Pending:
      return (
        <Tag className="ml-2" color="orange">
          {status}
        </Tag>
      );
    case OrderStatus.Partial:
      return (
        <Tag className="ml-2" color="green">
          {status}
        </Tag>
      );
    case OrderStatus.Shipped:
      return (
        <Tag className="ml-2" color="green">
          {status}
        </Tag>
      );
    case OrderStatus.Delivered:
      return (
        <Tag className="ml-2" color="blue">
          {status}
        </Tag>
      );
    case OrderStatus.Canceled:
      return (
        <Tag className="ml-2" color="red">
          {status}
        </Tag>
      );
    default:
      break;
  }
};
