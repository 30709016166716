import productType from "../../constants/productType";
import { totalOrderPriceForBuyOneGetOne } from "../../util";

//Constant
export const ADD_CART = "ADD_CART";
export const ADD_CART_FROM_API = "ADD_CART_FROM_API";
export const CLEAR_CART = "CLEAR_CART";
export const CLEAR_CART_REGULAR = "CLEAR_CART_REGULAR";
export const CLEAR_CART_PRE_ORDER = "CLEAR_CART_PRE_ORDER";
export const DELETE_CART = "DELETE_CART";
export const DELETE_CART_PRE_ORDER = "DELETE_CART_PRE_ORDER";
export const ADD_CART_PRE_ORDER = "ADD_CART_PRE_ORDER";
export const ADD_CART_PRE_ORDER_FROM_API = "ADD_CART_PRE_ORDER_FROM_API";
export const CART_QUANTITY_UPDATE = "CART_QUANTITY_UPDATE";
export const CART_PRODUCT_UPDATE = "CART_PRODUCT_UPDATE";
export const CART_PRODUCT_UPDATE_PRE_ORDER = "CART_PRODUCT_UPDATE_PRE_ORDER";
export const BOGO_UPDATE = "BOGO_UPDATE";
// export const UPDATE_CART_MAX_QUATITY = "UPDATE_CART_MAX_QUATITY"

// initial state

const cartInitial = {
  count: 0,
  items: [],
  preItems: [],
  // orderType: "",
};
const initialState = {
  ...cartInitial,
};

export const CartRducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case BOGO_UPDATE:
      var items = state?.items ? [...state?.items] : [];
      var preItems = state?.preItems ? [...state?.preItems] : [];
      items = items.map((prod) => ({ ...prod, bogo: false }));
      preItems = preItems.map((prod) => ({ ...prod, bogo: false }));
      let [totalPrice, bogoProdidx] = totalOrderPriceForBuyOneGetOne([
        ...items,
        ...preItems,
      ]);
      if (bogoProdidx !== -1) {
        if (totalPrice >= 188) {
          if (items.length > bogoProdidx) {
            items[bogoProdidx] = { ...items[bogoProdidx], bogo: true };
          } else if (preItems.length > bogoProdidx) {
            preItems[bogoProdidx] = { ...preItems[bogoProdidx], bogo: true };
          }
        } else {
          if (items.length > bogoProdidx) {
            items[bogoProdidx] = { ...items[bogoProdidx], bogo: false };
          } else if (preItems.length > bogoProdidx) {
            preItems[bogoProdidx] = { ...preItems[bogoProdidx], bogo: false };
          }
        }
      }
      return { ...state, items, preItems };
    case ADD_CART:
      items = state?.items ? [...state?.items] : [];
      preItems = state?.preItems ? [...state?.preItems] : [];
      var orderType = state?.orderType;
      var count = state.count + 1;
      items.push(payload);
      return { ...state, count, items, preItems, orderType };
    case ADD_CART_PRE_ORDER:
      items = state?.items ? [...state?.items] : [];
      preItems = state?.preItems ? [...state?.preItems] : [];
      orderType = state?.orderType;
      count = state.count + 1;
      preItems.push(payload);
      return { ...state, count, items: items, preItems: preItems, orderType };
    case ADD_CART_FROM_API:
      var newItem = state?.items ? [...state?.items] : [];
      count = state.count;
      var index = newItem.findIndex(
        (item) =>
          (!payload.isAccessories &&
            payload.bottomName === item.bottomName &&
            payload.topName === item.topName &&
            payload._id === item._id) ||
          (payload.isAccessories && payload._id === item._id)
      );
      if (index === -1) {
        newItem.push(payload);
        count = count + payload.quantity || 1;
      } else {
        if (payload.cartId) {
          newItem[index].cartId = payload.cartId;
        }
      }
      return { ...state, items: newItem, count: count };
    case ADD_CART_PRE_ORDER_FROM_API:
      newItem = state?.preItems ? [...state?.preItems] : [];
      count = state.count;
      index = newItem.findIndex(
        (item) =>
          (!payload.isAccessories &&
            payload.bottomName === item.bottomName &&
            payload.topName === item.topName &&
            payload._id === item._id) ||
          (payload.isAccessories && payload._id === item._id)
      );
      if (index === -1) {
        newItem.push(payload);
        count = count + payload.quantity;
      }
      return { ...state, preItems: newItem, count: count };

    case CART_PRODUCT_UPDATE:
      // console.log(payload)
      return {
        ...state,
        items: [...payload?.cartItem],
        preItems: [...payload?.preItem],
        count: count,
      };
    // case CART_PRODUCT_UPDATE_PRE_ORDER:
    //   return { ...state, preItems: payload.items, count: count , orderType:"pre-order"};

    case CART_QUANTITY_UPDATE:
      const { cartItem, quantity } = payload;

      // check it is preitems or not
      const selectCartType = payload?.pre ? state.preItems : state.items;
      var data = selectCartType.map((item) => {
        if (
          cartItem.isAccessories &&
          item?._id === cartItem?._id &&
          item.style === cartItem.style
        ) {
          item = { ...item, quantity: quantity };
          return item;
        } else if (
          !cartItem?.isAccessories &&
          item?._id === cartItem?._id &&
          ((cartItem?.part === productType.fullSet &&
            item?.part === productType.fullSet &&
            item?.topSizing?.size?._id === cartItem?.topSizing?.size?._id &&
            item?.bottomSizing?.size?._id ===
              cartItem?.bottomSizing?.size?._id) ||
            (cartItem?.part === productType.top &&
              item?.part === productType.top &&
              item?.topSizing?.size._id === cartItem?.topSizing?.size?._id) ||
            (cartItem?.part === productType.bottom &&
              item?.part === productType.bottom &&
              item?.bottomSizing?.size._id ===
                cartItem?.bottomSizing?.size?._id))
        ) {
          item = { ...item, quantity: quantity };
          return item;
        }
        return item;
        // if (item?._id !== payload?._id) {
        //   return item;
        // } else if (!payload.isAccessories) {
        //   if (
        // item?._id !== payload?._id ||
        // item?.topSizing?.size._id !== payload?.topSizing?.size?._id ||
        // item?.bottomSizing?.size?._id !== payload?.bottomSizing?.size?._id
        //   ) {
        //     return item;
        //   }
        // }
      });
      return { ...state, [payload.pre ? "preItems" : "items"]: data };

    case DELETE_CART:
      var count1 = state?.count - (payload?.quantity || 1);
      data = state.items.filter((item) => {
        const { bottomName, topName, topSizing, bottomSizing, _id } = item;
        if (_id !== payload?._id) {
          return item;
        } else if (!payload.isAccessories) {
          if (
            _id !== payload?._id ||
            (!bottomName &&
              (topSizing?.size._id !== payload?.topSizing?.size?._id ||
                payload?.bottomName)) ||
            (!topName &&
              (bottomSizing?.size?._id !== payload?.bottomSizing?.size?._id ||
                payload?.topName)) ||
            (bottomName &&
              topName &&
              (topSizing?.size._id !== payload?.topSizing?.size?._id ||
                bottomSizing?.size?._id !== payload?.bottomSizing?.size?._id))
          ) {
            return item;
          }
        } else {
          if (payload.style !== item.style) {
            return item;
          }
        }
      });
      return { ...state, count: count1, items: data };
    case DELETE_CART_PRE_ORDER:
      count1 = state.count - (payload?.quantity || 0);
      data = state.preItems.filter((item) => {
        const { bottomName, topName, topSizing, bottomSizing, _id } = item;
        if (_id !== payload?._id) {
          return item;
        } else if (!payload.isAccessories) {
          if (
            _id !== payload?._id ||
            (!bottomName &&
              (topSizing?.size._id !== payload?.topSizing?.size?._id ||
                payload?.bottomName)) ||
            (!topName &&
              (bottomSizing?.size?._id !== payload?.bottomSizing?.size?._id ||
                payload?.topName)) ||
            (bottomName &&
              topName &&
              (topSizing?.size._id !== payload?.topSizing?.size?._id ||
                bottomSizing?.size?._id !== payload?.bottomSizing?.size?._id))
          ) {
            return item;
          }
        }
      });
      return { ...state, count: count1, preItems: data };
    case CLEAR_CART_REGULAR:
      return {
        ...state,
        count: state.preItems.length,
        items: [],
      };
    case CLEAR_CART_PRE_ORDER:
      return { ...state, count: state.items.length, preItems: [] };
    case CLEAR_CART:
      return { ...initialState, ...cartInitial };
    // case UPDATE_CART_MAX_QUATITY:
    //   return {...state, cartQuantityLimit: payload};
    default: {
      return { ...state };
    }
  }
};


export const updateBogo = ()=>{
  return {
    type: BOGO_UPDATE,
  }
}

// functions
export const addItemToCart = (item) => {
  return {
    type: ADD_CART,
    payload: item,
  };
};

export const addItemToCartPreOrder = (item) => {
  return {
    type: ADD_CART_PRE_ORDER,
    payload: item,
  };
};

export const addItemToCartPreOrderFromApi = (item) => {
  return {
    type: ADD_CART_PRE_ORDER_FROM_API,
    payload: item,
  };
};

export const addItemToCartFromApi = (item) => {
  return {
    type: ADD_CART_FROM_API,
    payload: item,
  };
};

export const updateCartProductStore = (item) => {
  return {
    type: CART_PRODUCT_UPDATE,
    payload: item,
  };
};

// export const updatePreOrder = (item) => {
//   return {
//     type: CART_PRODUCT_UPDATE_PRE_ORDER,
//     payload: item,
//   };
// };

export const cartItemQuantityUpdate = (item, quantity, pre) => {
  return {
    type: CART_QUANTITY_UPDATE,
    payload: { cartItem: item, quantity, pre },
  };
};

export const deleteItemFromCart = (item) => {
  return {
    type: DELETE_CART,
    payload: item,
  };
};

export const deleteItemFromCartPreOrder = (item) => {
  return {
    type: DELETE_CART_PRE_ORDER,
    payload: item,
  };
};

export const clearAllItemFromCart = () => {
  return {
    type: CLEAR_CART,
  };
};
export const clearAllItemFromRegular = () => {
  return {
    type: CLEAR_CART_REGULAR,
  };
};
export const clearAllItemFromPreOrder = () => {
  return {
    type: CLEAR_CART_PRE_ORDER,
  };
};

// export const updateCartQuantityLimit = (payload=10) => {
//   return {
//     type: UPDATE_CART_MAX_QUATITY,
//     payload
//   };
// };

// //Constant
// export const ADD_CART = "ADD_CART";
// export const ADD_CART_FOR_PRE_ORDER = "ADD_CART_FOR_PRE_ORDER";
// export const ADD_CART_WITH_ORDER_AND_PRE_ORDER =
//   "ADD_CART_WITH_ORDER_AND_PRE_ORDER";
// export const CLEAR_CART = "CLEAR_CART";
// export const DELETE_SINGLE_CART = "DELETE_SINGLE_CART";

// export const ADD_CART_FROM_API = "ADD_CART_FROM_API";
// export const CLEAR_CART_REGULAR = "CLEAR_CART_REGULAR";
// export const CLEAR_CART_PRE_ORDER = "CLEAR_CART_PRE_ORDER";
// export const DELETE_CART_PRE_ORDER = "DELETE_CART_PRE_ORDER";
// export const ADD_CART_PRE_ORDER = "ADD_CART_PRE_ORDER";
// export const ADD_CART_PRE_ORDER_FROM_API = "ADD_CART_PRE_ORDER_FROM_API";
// export const DELETE_CART = "DELETE_SINGLE_CART";

// // initial state
// const initialState = {
//   count: 0,
//   items: [],
//   preItems: [],
//   orderType: "",
// };

// export const CartRducer = (state = initialState, action) => {
//   const { type, payload } = action;
//   switch (type) {
//     case ADD_CART: {
//       let flag = true;
//       const { items } = state;
//       const newItem = items.map((item) => {
//         const { product, topSizing, bottomSizing } = item || {};
//         if (
//           product._id === payload.product?._id &&
//           (payload.product?.isAccessories ||
//             (!payload.product?.isAccessories &&
//               topSizing === payload.topSizing &&
//               bottomSizing === payload.bottomSizing))
//         ) {
//           item.quantity = item.quantity + 1;
//           flag = false;
//         }
//         return item;
//       });
//       if (flag) {
//         newItem.push(payload);
//       }
//       return { ...state, items: newItem };
//     }
//     case ADD_CART_FOR_PRE_ORDER: {
//       let flag = true;
//       const { preItems } = state;
//       const newItem = preItems.map((item) => {
//         const { product, topSizing, bottomSizing } = item || {};
//         if (
//           product._id === payload.product?._id &&
//           (payload.product?.isAccessories ||
//             (!payload.product?.isAccessories &&
//               topSizing === payload.topSizing &&
//               bottomSizing === payload.bottomSizing))
//         ) {
//           item.quantity = item.quantity + 1;
//           flag = false;
//         }
//         return item;
//       });
//       if (flag) {
//         newItem.push(payload);
//       }
//       return { ...state, preItems: newItem };
//     }
//     case ADD_CART_WITH_ORDER_AND_PRE_ORDER:
//       // payload conatain preItem and items
//       return { ...state, ...payload };

//     // case DELETE_CART:
//     //   var count1 = state.count - (payload?.quantity || 0);
//     //   var data = state.items.filter((item) => {
//     //     if (item._id !== payload._id) {
//     //       return item;
//     //     } else {
//     //       if (!payload.isAccessories) {
//     //         if (
//     //           item?.topSizing?.size._id !== payload?.topSizing?.size._id ||
//     //           item?.bottomSizing?.size._id !== payload?.bottomSizing?.size._id
//     //         ) {
//     //           return item;
//     //         }
//     //       }
//     //     }
//     //   });
//     //   return { ...state, count: count1, items: data };
//     // case DELETE_CART_PRE_ORDER:
//     //   var count1 = state.count - (payload?.quantity || 0);
//     //   var data = state.preItems.filter((item) => {
//     //     if (item._id !== payload._id) {
//     //       return item;
//     //     } else {
//     //       if (!payload.isAccessories) {
//     //         if (
//     //           item?.topSizing?.size._id !== payload?.topSizing?.size._id ||
//     //           item?.bottomSizing?.size._id !== payload?.bottomSizing?.size._id
//     //         ) {
//     //           return item;
//     //         }
//     //       }
//     //     }
//     //   });
//     //   return { ...state, count: count1, preItems: data };
//     // case CLEAR_CART_REGULAR:
//     //   return {
//     //     count: state.preItems.length,
//     //     preItems: state.preItems,
//     //     items: [],
//     //   };
//     // case CLEAR_CART_PRE_ORDER:
//     //   return { count: state.items.length, items: state.items, preItems: [] };
//     case CLEAR_CART:
//       return initialState;
//     default: {
//       return state;
//     }
//   }
// };

// // functions
// export const addItemToCart = (item) => {
//   return {
//     type: ADD_CART,
//     payload: item,
//   };
// };

// export const addItemToCartPreOrder = (item) => {
//   return {
//     type: ADD_CART_FOR_PRE_ORDER,
//     payload: item,
//   };
// };

// export const deleteItemFromCart = (item) => {
//   return {
//     type: DELETE_SINGLE_CART,
//     payload: item,
//   };
// };

// export const clearAllItemFromCart = () => {
//   return {
//     type: CLEAR_CART,
//   };
// };
// export const clearAllItemFromRegular = () => {
//   return {
//     type: CLEAR_CART_REGULAR,
//   };
// };
// export const clearAllItemFromPreOrder = () => {
//   return {
//     type: CLEAR_CART_PRE_ORDER,
//   };
// };
