import gql from "graphql-tag";

export const FETCH_SHOPPING_CART = gql`
  query FetchShoppingCart($id: ID) {
    FetchShoppingCart(id: $id) {
      code
      result {
        _id
        topSizing
        bottomSizing
        quantity
        product {
          _id
          name
          description
          price
          isAccessories
          topSizing {
            _id
            quantity
            size {
              _id
              name
            }
          }
          bottomSizing {
            _id
            quantity
            size {
              _id
              name
            }
          }
          quantity
          gallery
          isAvailable
          defaultImg
        }
      }
    }
  }
`;

export const ADD_TO_SHOPPING_CART = gql`
  mutation AddToShoppingCart($data: [ShoppingInput]) {
    AddToShoppingCart(data: $data) {
      code
      success
      message
    }
  }
`;

export const CLEAR_ALL_SHOPPING_CART = gql`
  mutation ClearShoppingCart {
    ClearShoppingCart {
      code
      success
      message
    }
  }
`;

export const DELETE_SINGLE_CART = gql`
  mutation SingleDeleteShoppingCart($cartId: ID) {
    SingleDeleteShoppingCart(cartId: $cartId) {
      code
      success
      message
    }
  }
`;

export const FETCH_PRODUCT_AVAILABLE = gql`
  # Write your query or mutation here
  query FetchBulkProduct($productIds: [ID]) {
    FetchBulkProduct(productIds: $productIds) {
      code
      message
      success
      result {
        _id
        name
        # description
        price
        gallery
        colors {
          _id
          topColor
          bottomColor
          topColorName
          bottomColorName
          topGallery
          bottomGallery
          gallery
          topSizing {
            _id
            size {
              _id
              name
            }
            quantity
          }
          bottomSizing {
            _id
            size {
              _id
              name
            }
            quantity
          }
        }
        # gallery
        quantity
        isAccessories
        styles {
          name
          url
          quantity
        }
        status
      }
    }
  }
`;

export const SHOPPING_CART_QUANTITY_UPDATE = gql`
  mutation UpdateShoppingCartQuantity($id: ID, $quantity: Int) {
    UpdateShoppingCartQuantity(id: $id, quantity: $quantity) {
      code
      success
      message
    }
  }
`;

export const FETCH_DISCOUNT_ON_PURCHASE = gql`
  query FetchOrderDiscount {
    FetchOrderDiscount {
      result {
        _id
        validUntil
        amount
        discountAmount
        status
        createdAt
      }
    }
  }
`;

export const PRODUCT_UPDATE_SUBS = gql`
  subscription ProductUpdatedSub {
    ProductUpdatedSub {
      products
    }
  }
`;
