import { notification } from "antd";

const endpoint = "https://assestchillfitrave.s3.amazonaws.com/";
// const noImage = "/images/Rectangle 6.png";
const noImage = "/img/no-product.pn";
export const getImage = (image) => {
  if (!image) return noImage;
  return endpoint + image;
};

const noImageuser = "/img/user.jpeg";
export const getImageUser = (image) => {
  if (!image) return noImageuser;
  return endpoint + image;
};

const noProd = "/img/no-product.jpeg"
export const getWithDefaultImage = (image)=>{
  if (!image) return noProd;
  return endpoint + image;
}

export const showNotification = (data) => {
  if (data.success) {
    notification.success({
      message: data.message,
      placement: "bottomLeft",
    });
  } else {
    notification.error({
      message: data.message,
      placement: "bottomLeft",
    });
  }
};

export const checkEmptyData = (data) => {
  let result = data;
  if (!data.phone) {
    delete result.phone;
  }
  if (!data.age) {
    delete result.age;
  }
  if (!data.gender) {
    delete result.gender;
  }
  if (!data.zipCode) {
    delete result.zipCode;
  }
  if (!data.timeZone) {
    delete result.timeZone;
  }

  return result;
};

export const AddressFormatter = (data) => {
  let address = "";
  if (data?.address) {
    address = address.concat(data?.address);
  }
  if (data?.apertment) {
    address = address.concat(" ", data?.apertment);
  }
  if (data?.city) {
    address = address.concat(", ", data.city);
  }
  if (data?.state) {
    address = address.concat(", ", data.state);
  }
  if (data?.zipCode) {
    address = address.concat("-", data.zipCode);
  }
  address = address.concat(", ", "US");
  // if (data?.country) {
  //   address = address.concat(", ", 'US');
  // }
  return address;
};

export const getFormatAddress = (data) => {
  let first = "";
  let second = "";
  if (data?.address) {
    first = first.concat(data?.address);
  }
  if (data?.apertment) {
    first = first.concat(" ", data?.apertment);
  }
  if (data?.city) {
    second = second.concat(data.city);
  }
  if (data?.state) {
    second = second.concat(", ", data.state);
  }
  if (data?.zipCode) {
    second = second.concat(" ", data.zipCode);
  }
  return { first, second };
};

export const organizeSubmenu = (subcategory = []) => {
  let res = [];
  let count =
    parseInt(subcategory?.length / 5) + (subcategory?.length % 5 !== 0 ? 1 : 0);
  let store = subcategory;
  for (let i = 0; i < count; i++) {
    let temp = [];
    let limit = 0;
    while (store?.length>0) {
      limit++;
      temp.push(store[0]);
      store.shift();
      if(limit===5){
        break;
      }
    }
    res[i] = [...temp];
  }
  return res;
};
